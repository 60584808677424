import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useLoginByPhoneCodeMutation, LoginByPhoneCodeMutation } from './gql/LoginByPhoneCode_gen'
import { useMutationNotifier } from '../../utils/useMutationNotifier'
import { useIntl } from 'react-intl'
import { LoginByPhoneCodeInput } from '../graphql/generated.types'

type UseLoginByPhoneCodeMutationOptions = UseGqlMutationCallbacks<
  LoginByPhoneCodeMutation,
  LoginByPhoneCodeInput
>

export const useLoginByPhoneCode = (mutationOptions?: UseLoginByPhoneCodeMutationOptions) => {
  const intl = useIntl()

  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'auth.login.verifying_code' }),
      onSuccess: false,
    },
    mutationOptions
  )

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(_mutationOptions, 'all')

  const { mutateAsync: loginByPhoneCode } = useLoginByPhoneCodeMutation()

  return useMutation((input) => loginByPhoneCode({ input }), mutationOptionsWithInvalidation)
}
