import { dateFormat } from '../../utils/formatters/dateFormat'
import {
  EmployeeAccountStatus,
  EmployeeEnrollmentRequirementStatus as _Status,
} from '../graphql/generated.types'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'
import { useReenrollmentStatus } from './useReenrollmentStatus'

const { Complete, Incomplete, Blocked } = _Status

const isIneligibleStatus = (accountStatus: EmployeeAccountStatus) =>
  accountStatus === EmployeeAccountStatus.Terminated || accountStatus === EmployeeAccountStatus.Ineligible

/**
 * enrollment flow is 'not_started' -> 'registered' -> 'spending_connected' -> 'active'
 *
 * why 'active' and not 'enrolled'? because the account status might be 'enrolled', but we
 * might still need to collect other enrollment-related information, e.g. employment start date
 *
 * re-enrollment flow is one of these:
 * 1. 'must_reenroll_by_confirming' -> 'active' (if EE originally enrolled at another org)
 * 2. 'must_reenroll_via_spending_connection' -> 'active' (if EE originally enrolled at the current org)
 *
 * (more about #1 and #2 at `useReenrollmentStatus`)
 *
 * Note that these states do not directly correspond to an employee's `accountStatus`
 */
export type EnrollmentCompletionStatus =
  | 'ineligible'
  | 'not_started'
  | 'registered'
  | 'phone_verified'
  | 'spending_connected'
  | 'active'
  | 'must_reenroll_by_confirming'
  | 'must_reenroll_via_spending_connection'

/**
 * This hook derives enrollment-related state from user state - the main attributes
 * to look for are:
 * 1. employee.enrollmentInfo.accountStatus
 * 2. employee.enrollmentInfo.requirements
 * 3. employee.enrollmentInfo.enrollmentDate (reenrollment)
 * 4. localStorage flag (reenrollmentStartedWithoutSpendingConnection)
 */
export const useEnrollmentStatus = (): EnrollmentCompletionStatus | undefined => {
  const reenrollmentStatus = useReenrollmentStatus()

  const { data: employeeEnrollmentData, isLoading, isError } = useEmployeeEnrollmentInfoQuery()
  if (isLoading) return undefined // indeterminate state
  if (isError) return 'not_started'

  const employee = employeeEnrollmentData?.employee
  if (!employee) return 'not_started' // logged out - it's ok to show the enrollment flow

  const { accountStatus, requirements } = employee.enrollmentInfo

  if (isIneligibleStatus(accountStatus)) return 'ineligible'

  if (accountStatus === EmployeeAccountStatus.Enrolled) {
    // Short circuit - if user is enrolled, we ignore all other enrollment requirements
    return requirements.employmentStartDate === Complete ? 'active' : 'spending_connected'
  }

  if (requirements.registration === Blocked) return 'ineligible'
  if (requirements.registration === Incomplete) return 'not_started'

  if (reenrollmentStatus) return reenrollmentStatus
  if (requirements.phoneVerification !== Complete) return 'registered'

  if (requirements.spendingConnection !== Complete) return 'phone_verified'
  if (requirements.employmentStartDate !== Complete) return 'spending_connected'

  // user doesn't have accountStatus === EmployeeAccountStatus.Enrolled but has all other requirements
  return 'active'
}

export const useIsEnrolling = () => {
  const enrollmentStatus = useEnrollmentStatus()
  if (enrollmentStatus === undefined) return undefined
  return enrollmentStatus !== 'ineligible' && enrollmentStatus !== 'active'
}

export const useEnrolledToday = () => {
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const enrollmentDate = employeeEnrollmentData?.employee?.enrollmentInfo?.enrollmentDate
  const enrolledToday = enrollmentDate === dateFormat.inputVal(new Date())
  return enrolledToday
}
