import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, RouteObject } from 'react-router'
import { Authenticate } from '../auth/AuthenticateRoutes'
import { RouteChrome } from '../routes/RouteChrome'
import { CardsIndex } from './cards/CardsIndex'
import { AliceCardList } from './cards/alice/AliceCardList'
import { AliceCardRequest } from './cards/alice/AliceCardRequest'
import { BankingConnections } from './cards/personal/BankingConnections'
import { ConnectFinicityBankRoute } from './cards/personal/connect/ConnectFinicityBank'
import { ConnectionDetailRoute } from './cards/personal/connect/ConnectionDetail'
import { EmployeeHome } from './homepage/EmployeeHomepage'
import { ProfileIndex } from './profile/ProfileIndex'
import { SpendingSummary } from './spending/SpendingSummary'
import { TxnDetail } from './spending/detail/TxnDetail'
import { MissingTransaction } from './spending/missingTxn/MissingTransaction'

/**
 * These are the employee dashboard routes that should be mounted at the root of
 * the application.
 */
export const employeeDashboardRoutes: RouteObject = {
  element: (
    <Authenticate requiredProfile="employee">
      <RouteChrome type="employee" root={{ route: '/dashboard', label: 'Home' }} />
    </Authenticate>
  ),
  children: [
    {
      path: 'dashboard',
      children: [
        {
          index: true,
          element: <EmployeeHome />,
        },
        { path: 'confirm-transactions', element: <Navigate to="/spending" replace /> },
        { path: 'documents', element: <Navigate to="/profile" replace /> },
      ],
    },
    {
      path: 'spending',
      children: [
        { index: true, element: <SpendingSummary /> },
        { path: 'new', element: <MissingTransaction /> },
        { path: ':txnId', element: <TxnDetail /> },
      ],
      handle: { title: <FormattedMessage id="spending.spending" /> },
    },
    {
      path: 'reimbursements',
      element: <div />, // currently empty because the page will reload to Ada but we don't want to show a 'not found' page
    },
    {
      path: 'cards',
      children: [
        { index: true, element: <CardsIndex /> },
        {
          path: 'alice',
          children: [
            { index: true, element: <AliceCardList /> },
            { path: 'request', element: <AliceCardRequest /> },
          ],
          handle: { title: <FormattedMessage id="transactions.payment_type.alice_card" /> },
        },
        {
          path: 'personal',
          children: [
            { index: true, element: <BankingConnections />, handle: { title: 'Personal' } },
            {
              path: ':bankingInstitutionId',
              children: [
                { index: true, element: <ConnectionDetailRoute />, handle: { title: 'Personal' } },
                { path: 'connect/finicity', element: <ConnectFinicityBankRoute /> },
              ],
              handle: { title: 'Personal' },
            },
          ],
        },
      ],
      handle: { title: <FormattedMessage id="cards.cards" /> },
    },
    {
      path: 'profile',
      element: <ProfileIndex />,
      handle: { title: <FormattedMessage id="menu.profile" /> },
    },
    {
      path: 'beta',
      children: [],
    },
  ],
}
