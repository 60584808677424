import * as React from 'react'
import { Navigate, useLocation, useParams } from 'react-router'
import { RouteChrome } from '../routes/RouteChrome'
import { OrgAdminList } from './OrgAdminList'
import { useOrgRolesQuery } from './organization/gql/orgRoles_gen'
import { orgRootPath } from './organization/useOrg'
import { useLoginPath } from '../auth/useLoginPath'

const NavigateToOrg = ({ orgId }: { orgId: number }) => {
  const location = useLocation()
  const pathname = location.pathname.replace(/^\/manage/i, orgRootPath(orgId))
  return <Navigate to={{ ...location, pathname }} replace />
}
/**
 * The root of the org context - since all org dashboards require an orgId param, this component
 * will try to redirect if there is only one org that the user is an admin of, or it will present
 * a navigation list of orgs if the user is an admin of more than one org.
 */
export const OrgRootRoute = () => {
  const { orgId: orgIdParam } = useParams()
  if (orgIdParam) throw new Error('OrgRoot should not be used in route with orgId param')
  const { data: orgRolesData } = useOrgRolesQuery()
  const loginPath = useLoginPath(useLocation(), { password: true }) // redirect to login (and back) if user is not logged in

  if (orgRolesData === undefined) return null // waiting for org to load
  if (orgRolesData.currentUser === null) return <Navigate to={loginPath} replace />
  const canonicalOrg = orgRolesData.organization
  const orgAdminRoles = orgRolesData.currentUser?.roles

  if (orgAdminRoles) {
    if (orgAdminRoles.length === 1 && orgAdminRoles[0]) {
      return <NavigateToOrg orgId={orgAdminRoles[0].resourceDisplayInfo.id} />
    }
    if (orgAdminRoles.length > 0) return <OrgAdminList adminRoles={orgAdminRoles} />
  }

  if (!canonicalOrg) return <RouteChrome type="admin" notFound />

  return <NavigateToOrg orgId={canonicalOrg.id} />
}
