import {
  alpha,
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemLink,
  ListItemText,
  Paper,
  PaperProps,
  Skeleton,
  Typography,
  TypographyProps,
  useTheme,
} from '@alice-financial/pretext-ui'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import * as React from 'react'

export type HealthcheckItemStatus = 'complete' | 'incomplete' | 'pending' | 'disabled'
export type InertHealthcheckItemProps = {
  status: HealthcheckItemStatus
  primary: string
  secondary?: string
  icon?: string | React.ReactNode
  action?: React.ReactNode
  todo?: boolean
}
export type HealthcheckItemProps = InertHealthcheckItemProps & {
  to: string
  isLoading?: boolean
  actionIcon?: React.ReactNode
}
const StatusIcon = ({ status, todo }: Pick<HealthcheckItemProps, 'status' | 'todo'>) => {
  if (status === 'complete') return <CheckBoxIcon color="primary" fontSize="large" />
  if (status === 'incomplete') {
    return todo ? (
      <CheckBoxOutlineBlankOutlinedIcon color="primary" fontSize="large" />
    ) : (
      <WarningIcon color="warning" fontSize="large" />
    )
  }
  if (status === 'pending') {
    return <IndeterminateCheckBoxOutlinedIcon style={{ fontSize: 30 }} />
  }
  if (status === 'disabled') {
    return <CheckBoxOutlineBlankOutlinedIcon color="disabled" fontSize="large" />
  }
  return null
}

const StatusIconOverride = ({ icon }: Pick<HealthcheckItemProps, 'icon'>) => {
  if (!icon) return null
  if (typeof icon === 'string') {
    return (
      <Typography fontSize="2em" lineHeight={1}>
        {icon}
      </Typography>
    )
  }
  return <>{icon}</>
}

export const StatusAvatar = ({
  icon,
  status,
  todo,
}: Pick<HealthcheckItemProps, 'status' | 'icon' | 'todo'>) => (
  <ListItemIcon>
    {icon ? <StatusIconOverride icon={icon} /> : <StatusIcon status={status} todo={todo} />}
  </ListItemIcon>
)

type HealthcheckTypographyProps<TElement extends React.ElementType = 'span'> = Omit<
  TypographyProps<TElement>,
  'variant' | 'fontWeight' | 'component'
>
const HealthcheckPrimary = (props: HealthcheckTypographyProps) => (
  <Typography variant="body2" fontWeight="bold" {...props} />
)
const HealthcheckSecondary = (props: HealthcheckTypographyProps<'p'>) => (
  <Typography variant="caption" component="p" {...props} />
)

export const HealthcheckItem = ({
  to,
  status: _status,
  primary,
  secondary,
  isLoading,
  icon,
  action,
  actionIcon,
  todo,
  ...props
}: HealthcheckItemProps) => {
  const statusToUse = isLoading ? 'pending' : _status
  const statusColor = useStatusColor(statusToUse, todo)

  const Wrapper = todo ? Paper : React.Fragment
  return (
    <Wrapper>
      <ListItem divider disableGutters disablePadding secondaryAction={action} {...props}>
        <ListItemLink to={to} color={statusColor ? alpha(statusColor, 1) : undefined} actionIcon={actionIcon}>
          <StatusAvatar icon={icon} status={statusToUse} todo={todo} />
          <ListItemText
            primary={
              isLoading ? <Skeleton width="20ch" /> : <HealthcheckPrimary>{primary}</HealthcheckPrimary>
            }
            secondary={
              isLoading && secondary ? (
                <Skeleton width="30ch" />
              ) : (
                <HealthcheckSecondary>{secondary}</HealthcheckSecondary>
              )
            }
            sx={{ margin: secondary ? 0 : undefined }}
          />
        </ListItemLink>
      </ListItem>
    </Wrapper>
  )
}

const useStatusColor = (status: HealthcheckItemStatus, todo?: boolean) => {
  const theme = useTheme()
  const incompleteColor = todo
    ? undefined
    : alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity)
  const statusColors = {
    complete: undefined,
    incomplete: incompleteColor,
    pending: alpha(theme.palette.text.disabled, theme.palette.action.hoverOpacity),
    disabled: alpha(theme.palette.text.disabled, theme.palette.action.hoverOpacity),
  }
  return statusColors[status]
}

/**
 * Use when you need a list item with a status avatar, but don't want it to be a link
 */
export const InertHealthcheckItem = ({
  primary,
  secondary,
  icon,
  status,
  action,
  todo,
}: InertHealthcheckItemProps) => {
  const statusColor = useStatusColor(status, todo)

  const Wrapper = todo ? Paper : React.Fragment
  return (
    <Wrapper>
      <ListItem divider alignItems="center" sx={{ backgroundColor: statusColor }}>
        <ListItemIcon>
          <StatusAvatar icon={icon} status={status} todo={todo} />
        </ListItemIcon>

        <ListItemText
          primary={<HealthcheckPrimary>{primary}</HealthcheckPrimary>}
          secondary={<HealthcheckSecondary>{secondary}</HealthcheckSecondary>}
        />

        {action && <ListItemText sx={{ flex: '0 0 auto' }} secondary={action} />}
      </ListItem>
    </Wrapper>
  )
}

const PaperWrapper = (props: PaperProps) => <Paper elevation={1} {...props} />
type HealthcheckListProps = {
  todo?: boolean
} & BoxProps
export const HealthcheckList = ({ children, todo, ...props }: HealthcheckListProps) => {
  return (
    <Box component={todo ? 'div' : PaperWrapper} {...props}>
      <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: todo ? 2 : 0 }}>
        {children}
      </List>
    </Box>
  )
}
