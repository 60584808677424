import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { NotificationPreferenceInput } from '../../graphql/generated.types'
import {
  UpdateNotificationPreferencesMutation,
  useUpdateNotificationPreferencesMutation,
} from './gql/updateNotificationPreferences_gen'
import { useAdminUserProfileQuery } from './gql/adminUserProfile_gen'

type UseUpdateNotificationPreferencesOptions = UseGqlMutationCallbacks<
  UpdateNotificationPreferencesMutation,
  { notificationPreferences: NotificationPreferenceInput[] }
>
export const useUpdateNotificationPreferences = (
  userId: number,
  mutationOptions?: UseUpdateNotificationPreferencesOptions
) => {
  const optionsWithNotifs = useMutationNotifier({}, mutationOptions)
  const optionsWithInvalidation = useMutationOptionsWithInvalidation(optionsWithNotifs, [
    useAdminUserProfileQuery.getKey(),
  ])
  const { mutateAsync } = useUpdateNotificationPreferencesMutation()
  return useMutation(
    ({ notificationPreferences }) => mutateAsync({ userId, notificationPreferences }),
    optionsWithInvalidation
  )
}
