import * as React from 'react'
import { useEmployeeQuery } from '../employeeDashboard/gql/employee_gen'
import { useCurrentUserQuery } from './gql/currentUser_gen'
import { EmployeeRole } from '../graphql/generated.types'
import { isNotNull } from '../../utils/typeUtils'

// this is the cache (localStorage) key for the current profile type - it will get a userId suffix to ensure
// that we have a different value for each user who uses the current browser
const CURRENT_PROFILE_TYPE_KEY = 'userProfileType'
const getKey = (userId: number | undefined) => `${CURRENT_PROFILE_TYPE_KEY}_${userId}`

const PROFILE_TYPES = {
  employee: 'Employee',
  organization_admin: 'Organization Admin',
  employer_admin: 'Employer Admin',
  provisional_admin: 'Provisional Admin',
  user: 'User',
}
export type UserProfileType = keyof typeof PROFILE_TYPES

const isUserProfileType = (value: string): value is UserProfileType =>
  Object.keys(PROFILE_TYPES).includes(value)

const setCurrentProfileType = (userId: number | undefined, profileType: UserProfileType) =>
  window.localStorage.setItem(getKey(userId), profileType)

const getCurrentProfileType = (userId: number | undefined): UserProfileType | null => {
  if (userId === undefined) return null
  const profileType = window.localStorage.getItem(getKey(userId))
  if (profileType && isUserProfileType(profileType)) return profileType
  return null
}

export type AvailableProfileTypes = [UserProfileType, ...Array<UserProfileType>] // minimum length of 1
/**
 * This hook determines which profile types are available to the current user, and returns them in an array
 * that is sorted by priority. The first item in the array is the profile type that should be used as the
 * default profile when the user logs in to the app for the first time. Future sessions will attempt to
 * use the profile type that was last used in the previous session.
 *
 * The priority order is as follows:
 * 1. Org admin
 * 2. Employer admin
 * 3. Employee
 * 4. User
 * 5. Provisional admin (have to opt in to this profile by navigating to /onboard)
 */
export const useAvailableProfileTypes = (): AvailableProfileTypes | undefined | null => {
  const {
    data: currentUserData,
    isLoading: isLoadingUser,
    isError: isLoadingUserError,
  } = useCurrentUserQuery()
  const {
    data: employeeData,
    isLoading: isLoadingEmployee,
    isError: isLoadingEmployeeError,
  } = useEmployeeQuery()
  const employee = employeeData?.employee

  if (isLoadingUser || isLoadingEmployee) return undefined
  if (isLoadingUserError || isLoadingEmployeeError) return null
  if (!currentUserData || !employeeData) return null

  const availableProfileTypes: AvailableProfileTypes = ['user', 'provisional_admin']

  if (employee) availableProfileTypes.unshift('employee')

  if (currentUserData.currentUser?.roles.length) {
    availableProfileTypes.unshift('organization_admin')
  } else if (employee?.role) {
    // These checks can be removed when we migrate roles data to user.roles
    if ([EmployeeRole.EmployerAdmin, EmployeeRole.OrganizationAdmin].includes(employee.role)) {
      availableProfileTypes.unshift('employer_admin')
      availableProfileTypes.unshift('organization_admin')
    }
  }

  return availableProfileTypes
}

/**
 * Necessary to determine whether or not the EE is redirected to the administrative screens on login.
 */
export const useCurrentProfileType = (): UserProfileType | undefined | null => {
  const { data: currentUserData } = useCurrentUserQuery()
  const userId = currentUserData?.currentUser?.id
  const currentProfileType = getCurrentProfileType(userId)
  const availableProfileTypes = useAvailableProfileTypes()

  if (availableProfileTypes === undefined) return undefined
  if (availableProfileTypes === null) return null

  if (currentProfileType) return currentProfileType

  const initialProfileType = availableProfileTypes[0]
  if (currentProfileType !== initialProfileType) setCurrentProfileType(userId, initialProfileType)

  return initialProfileType
}

const useMatchingProfileType = (
  ...requiredProfileTypeOptions: AvailableProfileTypes
): UserProfileType | null | undefined => {
  const availableProfileTypes = useAvailableProfileTypes()

  if (availableProfileTypes === undefined) return undefined // still looking up what is available
  if (availableProfileTypes === null) return null // no profile types available - usually means there was an error

  return (
    requiredProfileTypeOptions
      .filter(isNotNull)
      .find((profileType) => availableProfileTypes.includes(profileType)) || null
  )
}

/**
 * This hook will determine whether the current user has the required profile type available,
 * and persist that as the 'current profile type' if so
 *
 * Return values:
 * - `undefined`: the app is still trying to determine whether the required profile type is available
 * - `false`: the required profile type is not available
 * - `true`: the required profile type is available and will be used for future 'current profile type' lookups
 */
export const useHasProfileType = (...profileTypeOptions: AvailableProfileTypes): boolean | undefined => {
  const matchingProfileType = useMatchingProfileType(...profileTypeOptions)
  if (profileTypeOptions.every((profileType) => profileType === undefined)) return true

  if (matchingProfileType === undefined) return undefined // still looking up what is available

  const hasAtLeastOneMatchingProfile = Boolean(matchingProfileType)
  return hasAtLeastOneMatchingProfile
}

export const useRequiredProfileType = (...requiredProfileTypeOptions: AvailableProfileTypes) => {
  const matchingProfileType = useMatchingProfileType(...requiredProfileTypeOptions)
  const { data: currentUserData } = useCurrentUserQuery()
  const userId = currentUserData?.currentUser?.id
  const noRequiredProfileType = requiredProfileTypeOptions.every((value) => value === undefined)
  React.useEffect(() => {
    const currentProfileType = getCurrentProfileType(userId)
    if (
      noRequiredProfileType ||
      !matchingProfileType ||
      (currentProfileType && requiredProfileTypeOptions.includes(currentProfileType))
    ) {
      return
    }

    setCurrentProfileType(userId, matchingProfileType)
  }, [userId, matchingProfileType, noRequiredProfileType, requiredProfileTypeOptions])

  return noRequiredProfileType || matchingProfileType
}
