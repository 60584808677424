import { fetchRest, useMutationWithInvalidation, UseRestMutationCallbacks } from '@alice-financial/api'
import { QueryKey } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { useOrgId } from '../useOrgId'
import { useOrg } from './useOrg'

type OrganizationLogoSubmitValues = {
  logo: File
  id: number
}

const submitOrganizationLogo = ({ logo, id }: OrganizationLogoSubmitValues) => {
  const body = new FormData()
  body.append('logo', logo)

  return fetchRest(`/api/organization/${id}/logo`, {
    method: 'POST',
    body,
  })
}

type UseSubmitOrganizationLogoMutationOptions = UseRestMutationCallbacks<
  unknown,
  OrganizationLogoSubmitValues
>

export const useUpdateOrganizationLogo = (mutationOptions: UseSubmitOrganizationLogoMutationOptions = {}) => {
  const intl = useIntl()
  const _mutationOptions = useMutationNotifier(
    {
      onMutate: intl.formatMessage({ id: 'organization.onboarding.locations.submission_notice' }),
      onSuccess: intl.formatMessage({ id: 'organization.onboarding.locations.upload_success_notice' }),
    },
    mutationOptions
  )
  const invalidationKeys: QueryKey[] = [useOrg.getKey({ orgId: useOrgId() })]

  return useMutationWithInvalidation(submitOrganizationLogo, _mutationOptions, invalidationKeys)
}
