import { Box, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { PageBody } from '../../../routes/PageBody'
import { useOrg } from '../../organization/useOrg'
import { ConnectPaymentMethodStep } from '../ConnectPaymentMethodStep'
import { AliceCardProgramSetupInfo } from './AliceCardProgramSetupInfo'
import { UnifiedFundingForm } from './UnifiedFundingForm'

export const FundingAccountSetupPage = () => {
  const org = useOrg()
  if (!org) return null
  const fundingConnected = org.aliceCardProgram.hasFundingConnected
  if (fundingConnected) return <Navigate to="../../.." />
  const fundingStarted = (org.aliceCardFunding.availablePaymentMethods || []).length > 0
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking)

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Contributions Account setup
      </Typography>
      <AliceCardProgramSetupInfo />
      <Typography variant="body2" gutterBottom>
        Provide Payroll Bank Account information
        {fundingStarted && !hasUnifiedBanking && (
          <Typography variant="body2" fontWeight="bold" component="span">
            {' '}
            for each pay group
          </Typography>
        )}
        .
      </Typography>

      <Box width="100%">
        <UnifiedFundingForm />
        <ConnectPaymentMethodStep org={org} />
      </Box>
    </PageBody>
  )
}
