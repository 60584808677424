import {
  Box,
  Button,
  Container,
  FullDialog,
  IconButton,
  ListItemText,
  Typography,
  useInfoDrawerControl,
  useMediaQuery,
  useTheme,
} from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined'
import * as React from 'react'
import { PaymentMethodFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import {
  AliceCardFundingStatus,
  BankableTypeEnum,
  PaymentMethodStatus,
} from '../../../graphql/generated.types'
import { ConnectPaymentMethod } from './ConnectPaymentMethod'
import { getCurrentPaymentMethodConnection, isConnectionConnected } from './fundingUtils'
import { BankableEntity, TypedBankableEntity } from './types'

const PaymentMethodStatusMessage = ({ paymentMethod }: { paymentMethod: PaymentMethodFragment }) => {
  if (paymentMethod.status === 'active') {
    return (
      <Typography variant="body2" gutterBottom>
        Your current funding account is <strong>{paymentMethod.descriptor}</strong> .
      </Typography>
    )
  }
  if (paymentMethod.status === PaymentMethodStatus.PendingSetup) {
    return (
      <Typography variant="body2" gutterBottom>
        Your contributions account will be funded by <strong>{paymentMethod.descriptor}</strong>
      </Typography>
    )
  }
  return (
    <Typography variant="body2" gutterBottom>
      Your current funding account is <strong>{paymentMethod.descriptor}</strong>, which has been disconnected
    </Typography>
  )
}

type ConnectFundingButtonProps = {
  entity: BankableEntity
  onClick: () => void
}
const ConnectFundingButton = ({ entity, onClick }: ConnectFundingButtonProps) => {
  const theme = useTheme()
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const paymentConnection = getCurrentPaymentMethodConnection(entity.aliceCardFunding)
  const isConnected = isConnectionConnected(paymentConnection)
  return isMobileWidth ? (
    <IconButton onClick={onClick} sx={{ padding: 0 }}>
      {paymentConnection ? (
        <ChangeCircleOutlinedIcon color={isConnected ? 'primary' : 'error'} fontSize="large" />
      ) : (
        <AddCircleIcon color="primary" fontSize="large" />
      )}
    </IconButton>
  ) : (
    <Button
      variant={paymentConnection ? 'outlined' : 'contained'}
      color={!paymentConnection || isConnected ? 'primary' : 'error'}
      size="small"
      onClick={onClick}
      endIcon={!paymentConnection && <AddIcon />}
      sx={{ textWrap: 'nowrap' }}
    >
      {paymentConnection ? 'Choose another account' : 'Connect account'}
    </Button>
  )
}

type EntityFundingProps = {
  entity: TypedBankableEntity
  readonly?: boolean
}
/**
 * A flexbox-based one-line UI that displays the entity's name and a button to manage the funding connection
 * for the provided entity
 */
export const EntityFunding = ({ entity, readonly }: EntityFundingProps) => {
  const {
    type,
    name,
    id,
    aliceCardFunding: { availablePaymentMethods, status },
  } = entity
  const mayConnectToStripe = status !== AliceCardFundingStatus.Blocked
  const { onOpen, onClose, open } = useInfoDrawerControl(`${type}-${id}-connect-funding`)
  const paymentConnection = getCurrentPaymentMethodConnection(entity.aliceCardFunding)
  const isConnected = isConnectionConnected(paymentConnection)
  const currentPaymentMethod = paymentConnection?.paymentMethod
  return (
    <>
      <ListItemText
        primary={
          <Typography variant="body2" color="primary" fontWeight="bold">
            {type === BankableTypeEnum.Paygroup ? name : 'Funding account for all pay groups'}
          </Typography>
        }
        secondary={
          <Typography
            variant="caption"
            component="p"
            color={paymentConnection && !isConnected ? 'error' : undefined}
            fontStyle={paymentConnection && isConnected ? 'normal' : 'italic'}
          >
            {paymentConnection
              ? isConnected
                ? `acct ending in ${paymentConnection.paymentMethod.last4}`
                : 'disconnected'
              : 'not connected'}
          </Typography>
        }
      />
      {!readonly && (
        <Box>
          <ConnectFundingButton entity={entity} onClick={onOpen} />
        </Box>
      )}
      <FullDialog
        open={open}
        onClose={onClose}
        title={<Typography variant="h2">Connect Alice Card funding</Typography>}
      >
        {currentPaymentMethod && (
          <Container>
            <PaymentMethodStatusMessage paymentMethod={currentPaymentMethod} />
          </Container>
        )}
        <ConnectPaymentMethod
          entity={entity}
          availablePaymentMethods={availablePaymentMethods}
          currentPaymentMethod={currentPaymentMethod}
          onSuccess={onClose}
          mayConnectToStripe={mayConnectToStripe}
        />
      </FullDialog>
    </>
  )
}
