import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UpdateNotificationPreferencesMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input']
  notificationPreferences: Array<Types.NotificationPreferenceInput> | Types.NotificationPreferenceInput
}>

export type UpdateNotificationPreferencesMutation = {
  __typename?: 'Mutation'
  updateUser?: { __typename?: 'UpdateUserPayload'; user?: { __typename?: 'User'; id: number } | null } | null
}

export const UpdateNotificationPreferencesDocument = `
    mutation UpdateNotificationPreferences($userId: Int!, $notificationPreferences: [NotificationPreferenceInput!]!) {
  updateUser(
    input: {userId: $userId, notificationPreferences: $notificationPreferences}
  ) {
    user {
      id
    }
  }
}
    `

export const useUpdateNotificationPreferencesMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateNotificationPreferencesMutation,
    TError,
    UpdateNotificationPreferencesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateNotificationPreferencesMutation,
    TError,
    UpdateNotificationPreferencesMutationVariables,
    TContext
  >(
    ['UpdateNotificationPreferences'],
    (variables?: UpdateNotificationPreferencesMutationVariables) =>
      fetchGql<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>(
        UpdateNotificationPreferencesDocument,
        variables
      )(),
    options
  )
}
