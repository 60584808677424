import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { OrganizationFunding } from './funding/OrganizationFunding'
import { PaygroupFundingList } from './funding/PaygroupFunding'

type ConnectPaymentMethodStepProps = {
  org: OrganizationDetailFragment
  readonly?: boolean
}
export const ConnectPaymentMethodStep = ({ org, readonly }: ConnectPaymentMethodStepProps) => {
  if (org.aliceCardProgram.hasUnifiedBanking) {
    return <OrganizationFunding org={org} readonly={readonly} />
  }
  return <PaygroupFundingList paygroups={org.employers.filter(isNotNull)} readonly={readonly} />
}
