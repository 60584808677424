import { Grid } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocationFragment } from '../../graphql/fragments/WorkLocationFragment_gen'
import { EditWorkLocationButton } from './WorkLocationForm'
import { WorkLocationItem } from './WorkLocationItem'

type WorkLocationListProps = {
  org: OrganizationDetailFragment
}

export const WorkLocationList: React.FC<WorkLocationListProps> = ({ org }: WorkLocationListProps) => {
  const locations = org.workLocations.filter(isNotNull).sort((a, b) => {
    const displayName = (location: WorkLocationFragment) => location.name
    return displayName(a).localeCompare(displayName(b))
  })

  return (
    <Grid container spacing={1}>
      {locations.length > 0 &&
        locations.map((location) => (
          <Grid component="li" item xs={12} sm={12} sx={{ listStyle: 'none' }} key={location.id}>
            <WorkLocationItem org={org} location={location} />
          </Grid>
        ))}

      <Grid component="li" item xs={12} sm={12} container sx={{ listStyle: 'none', mt: 2 }}>
        <EditWorkLocationButton org={org} />
      </Grid>
    </Grid>
  )
}
