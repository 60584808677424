import * as Types from '../../graphql/generated.types'

import { TelephoneFragmentDoc } from '../../graphql/fragments/TelephoneFragment_gen'
import { AddressFragmentDoc } from '../../graphql/fragments/AddressFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type EmployeeEnrollmentInfoQueryVariables = Types.Exact<{ [key: string]: never }>

export type EmployeeEnrollmentInfoQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    firstName?: string | null
    lastName?: string | null
    preferredName?: string | null
    hasDummyPassword: boolean
    birthdate?: string | null
    email?: string | null
    tel?: {
      __typename?: 'Telephone'
      nationalNumber: string
      isSmsConfirmed?: boolean | null
      isConfirmationCodeSent?: boolean | null
    } | null
  } | null
  employee?: {
    __typename?: 'Employee'
    id: number
    tosAcceptedAt?: string | null
    employmentStartDate?: string | null
    mailingAddress?: {
      __typename?: 'AddressDisplay'
      isVerified: boolean
      isMailing: boolean
      line1?: string | null
      line2?: string | null
      cityName?: string | null
      stateCode?: string | null
      zipcode?: string | null
    } | null
    payrollAddress?: {
      __typename?: 'AddressDisplay'
      isVerified: boolean
      isMailing: boolean
      line1?: string | null
      line2?: string | null
      cityName?: string | null
      stateCode?: string | null
      zipcode?: string | null
    } | null
    spendingConnectionInfo: {
      __typename?: 'SpendingConnectionInfo'
      hasAcceptedAliceCardTos: boolean
      aliceCardAccounts?: Array<{
        __typename?: 'AliceCardAccount'
        id: number
        status: Types.AliceCardAccountStatus
      }> | null
    }
    enrollmentInfo: {
      __typename?: 'EmployeeEnrollment'
      enrollmentDate?: string | null
      accountStatus: Types.EmployeeAccountStatus
      registrationBlocker?: Types.EmployeeRegistrationBlocker | null
      enrollmentBlocker?: Types.EmployeeEnrollmentBlocker | null
      requirements: {
        __typename?: 'EmployeeEnrollmentRequirements'
        registration: Types.EmployeeEnrollmentRequirementStatus
        phoneVerification: Types.EmployeeEnrollmentRequirementStatus
        spendingConnection: Types.EmployeeEnrollmentRequirementStatus
        employmentStartDate: Types.EmployeeEnrollmentRequirementStatus
      }
    }
    paygroupInfo: { __typename?: 'EmployerInfo'; id: number; marketingFriendlyName?: string | null }
  } | null
}

export type EnrollmentInfoFragment = {
  __typename?: 'EmployeeEnrollment'
  enrollmentDate?: string | null
  accountStatus: Types.EmployeeAccountStatus
  registrationBlocker?: Types.EmployeeRegistrationBlocker | null
  enrollmentBlocker?: Types.EmployeeEnrollmentBlocker | null
  requirements: {
    __typename?: 'EmployeeEnrollmentRequirements'
    registration: Types.EmployeeEnrollmentRequirementStatus
    phoneVerification: Types.EmployeeEnrollmentRequirementStatus
    spendingConnection: Types.EmployeeEnrollmentRequirementStatus
    employmentStartDate: Types.EmployeeEnrollmentRequirementStatus
  }
}

export const EnrollmentInfoFragmentDoc = `
    fragment EnrollmentInfo on EmployeeEnrollment {
  enrollmentDate
  accountStatus
  requirements {
    registration
    phoneVerification
    spendingConnection
    employmentStartDate
  }
  registrationBlocker
  enrollmentBlocker
}
    `
export const EmployeeEnrollmentInfoDocument = `
    query EmployeeEnrollmentInfo {
  currentUser {
    id
    firstName
    lastName
    preferredName
    hasDummyPassword
    birthdate
    email
    tel {
      ...Telephone
    }
  }
  employee {
    id
    tosAcceptedAt
    mailingAddress {
      ...Address
    }
    payrollAddress {
      ...Address
    }
    spendingConnectionInfo {
      hasAcceptedAliceCardTos
      aliceCardAccounts {
        id
        status
      }
    }
    employmentStartDate
    enrollmentInfo {
      ...EnrollmentInfo
    }
    paygroupInfo {
      id
      marketingFriendlyName
    }
  }
}
    ${TelephoneFragmentDoc}
${AddressFragmentDoc}
${EnrollmentInfoFragmentDoc}`

export const useEmployeeEnrollmentInfoQuery = <TData = EmployeeEnrollmentInfoQuery, TError = Error>(
  variables?: EmployeeEnrollmentInfoQueryVariables,
  options?: UseQueryOptions<EmployeeEnrollmentInfoQuery, TError, TData>
) => {
  return useQuery<EmployeeEnrollmentInfoQuery, TError, TData>(
    variables === undefined ? ['EmployeeEnrollmentInfo'] : ['EmployeeEnrollmentInfo', variables],
    fetchGql<EmployeeEnrollmentInfoQuery, EmployeeEnrollmentInfoQueryVariables>(
      EmployeeEnrollmentInfoDocument,
      variables
    ),
    options
  )
}

useEmployeeEnrollmentInfoQuery.getKey = (variables?: EmployeeEnrollmentInfoQueryVariables) =>
  variables === undefined ? ['EmployeeEnrollmentInfo'] : ['EmployeeEnrollmentInfo', variables]
