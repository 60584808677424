import {
  composeMutationCallbacks,
  UseGqlMutationCallbacks,
  useMutationOptionsWithInvalidation,
} from '@alice-financial/api'
import { useResponseNotification } from '@alice-financial/pretext-ui'
import { QueryKey, useMutation } from '@tanstack/react-query'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { CreateWorkLocationInput } from '../../graphql/generated.types'
import {
  CreateWorkLocationMutationVariables,
  useCreateWorkLocationMutation,
} from '../../organization/onboarding/gql/createWorkLocation_gen'
import { useUpdateWorkLocationMutation } from '../../organization/onboarding/gql/updateWorkLocation_gen'
import { useUpdateOrganizationLogo } from '../organization/useUpdateOrganizationLogo'
import { WorkLocationFormInputs } from './types'
import { useUpdateWorkLocationLogo } from './useUpdateWorkLocationLogo'

export type LocationData = Omit<CreateWorkLocationInput, 'orgId'>

type UseManageWorkLocationInput = WorkLocationFormInputs
type UseCreateLocationMutationOptions = UseGqlMutationCallbacks<
  { id: number | string },
  UseManageWorkLocationInput
>

const locationToInput = (orgId: number, locationData: LocationData): CreateWorkLocationMutationVariables => {
  return { input: { ...locationData, orgId } }
}

// This hook is meant to take a form state and determine, based on the presence of an id field,
// whether to execute an update or a create mutation. In either case, it will also fire a second
// updateWorkLocationLogo mutation after success of the original write - this is because logo
// upload actually exists on a separate endpoint.
export const useManageWorkLocation = (
  org: OrganizationDetailFragment,
  invalidationKeys: QueryKey[],
  mutationOptions?: UseCreateLocationMutationOptions
) => {
  const { notifyError } = useResponseNotification()
  const composedMutationOptions = composeMutationCallbacks(mutationOptions, {
    onSuccess: (data) => {
      if (!data.id) {
        notifyError('Could not create/update location')
      }
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    composedMutationOptions,
    invalidationKeys
  )

  const { mutateAsync: createWorkLocation } = useCreateWorkLocationMutation()
  const { mutateAsync: updateWorkLocation } = useUpdateWorkLocationMutation()
  const { mutateAsync: updateOrganizationLogo } = useUpdateOrganizationLogo()
  const { mutateAsync: updateLocationLogo } = useUpdateWorkLocationLogo()
  const orgHasLogo = Boolean(org.logo)
  const orgHasMultipleLocations = org.workLocations?.length > 1

  return useMutation(
    ['manageWorkLocation'],
    async ({ name, address, latitude, longitude, logo, id }) => {
      const locationData: LocationData = {
        name,
        address,
        latitude,
        longitude,
      }
      const locationInputData = locationToInput(org.id, locationData)
      const mutationPromise = id
        ? updateWorkLocation({ input: { id, ...locationData } }).then(() => id)
        : createWorkLocation(locationInputData).then((result) => result.createWorkLocation?.workLocation?.id)

      const workLocationId = await mutationPromise
      if (!workLocationId) throw new Error('Work location not created')
      if (logo !== undefined) {
        // The first logo will always be for the organization
        // Future logos will be for the location only if there are multiple locations
        if (orgHasLogo && orgHasMultipleLocations) {
          await updateLocationLogo({ logo, id: parseInt(workLocationId?.toString(), 10) })
        } else if (logo) {
          await updateOrganizationLogo({ logo, id: org.id })
        }
      }
      return { id: workLocationId }
    },
    mutationOptionsWithInvalidation
  )
}
