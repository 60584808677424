import { ListItem, ListItemIcon, ListItemText } from '@alice-financial/pretext-ui'

import AliceCardIllustration from 'jsx:./assets/alice-card-illustration.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { AliceCardProvisioningStatus, AliceCardType } from '../../../graphql/generated.types'
import { AliceCardFragment } from '../gql/connectedCardsQuery_gen'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import {
  findActiveCardAccount,
  getAvailableInstantAliceCard,
  getAvailablePhysicalAliceCard,
} from './aliceCardUtils'

type CardItemProps = {
  card?: AliceCardFragment
  provisioningStatus: AliceCardProvisioningStatus | null | undefined
}
const CardStatusText = ({ card, provisioningStatus }: CardItemProps) => {
  const {
    data: { aliceCardFundingConnected },
  } = useQuerySpendingConnections()

  if (!card) {
    if (!provisioningStatus) return <FormattedMessage id="cards.alice.request_alice_card_both" />
    // assume card has been requested
    return aliceCardFundingConnected ? (
      <em>
        <FormattedMessage id="cards.alice.card_status.processing" />
      </em>
    ) : (
      <FormattedMessage id="cards.alice.card_status.balance_gated" />
    )
  }

  if (card.cardType === AliceCardType.Instant && !card.isConnectedToDigitalWallet) {
    return (
      <em>
        <FormattedMessage id="cards.alice.card_status.add_to_wallet" />
      </em>
    )
  }
  return <FormattedMessage id="cards.alice.card_status.ready" />
}

const CardItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <AliceCardIllustration />
        {/* <CheckCircleIcon color="primary" /> */}
        {/* TODO: use status to determine icon */}
      </ListItemIcon>
      {children}
    </ListItem>
  )
}
const InstantCardItem = ({ card, provisioningStatus }: CardItemProps) => {
  const primaryText = card ? (
    <FormattedMessage id="cards.alice.instant_card_label" values={{ last4: card.last4 }} />
  ) : (
    <span>Instant Alice Card</span>
  )
  return (
    <CardItem>
      <ListItemText
        primary={<strong>{primaryText}</strong>}
        secondary={<CardStatusText card={card} provisioningStatus={provisioningStatus} />}
      />
    </CardItem>
  )
}

const PhysicalCardItem = ({ card, provisioningStatus }: CardItemProps) => {
  if (!card && !provisioningStatus) return null
  const primaryText = card ? (
    <FormattedMessage id="cards.alice.physical_card_label" values={{ last4: card.last4 }} />
  ) : (
    <span>Physical Alice Card</span>
  )
  return (
    <CardItem>
      <ListItemText
        primary={<strong>{primaryText}</strong>}
        secondary={<CardStatusText card={card} provisioningStatus={provisioningStatus} />}
      />
    </CardItem>
  )
}

/**
 * ReturnsListItems that represent the state of the user's Alice Card
 * connections, if any
 * @returns React.ReactElement | null
 */
export const AliceCardItems = () => {
  const {
    data: { aliceCardAccounts },
  } = useQuerySpendingConnections()
  const activeCardAccount = findActiveCardAccount(aliceCardAccounts)
  const provisioningStatus = activeCardAccount?.cardProvisioningStatus

  const instantCard = activeCardAccount && getAvailableInstantAliceCard(activeCardAccount.aliceCards)
  const physicalCard = activeCardAccount && getAvailablePhysicalAliceCard(activeCardAccount.aliceCards)

  if (!instantCard && !physicalCard && !provisioningStatus?.instant && !provisioningStatus?.physical) {
    return null
  }
  return (
    <>
      <InstantCardItem card={instantCard} provisioningStatus={provisioningStatus?.instant} />
      <PhysicalCardItem card={physicalCard} provisioningStatus={provisioningStatus?.physical} />
    </>
  )
}
