import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type AdminUserProfileQueryVariables = Types.Exact<{ [key: string]: never }>

export type AdminUserProfileQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    notificationPreferences: Array<{
      __typename?: 'NotificationPreference'
      id: number
      notificationType: Types.NotificationType
      channel: Types.NotificationChannel
      enabled: boolean
    }>
  } | null
}

export type AdminUserProfileFragment = {
  __typename?: 'User'
  id: number
  notificationPreferences: Array<{
    __typename?: 'NotificationPreference'
    id: number
    notificationType: Types.NotificationType
    channel: Types.NotificationChannel
    enabled: boolean
  }>
}

export const AdminUserProfileFragmentDoc = `
    fragment AdminUserProfile on User {
  id
  notificationPreferences {
    id
    notificationType
    channel
    enabled
  }
}
    `
export const AdminUserProfileDocument = `
    query AdminUserProfile {
  currentUser {
    ...AdminUserProfile
  }
}
    ${AdminUserProfileFragmentDoc}`

export const useAdminUserProfileQuery = <TData = AdminUserProfileQuery, TError = Error>(
  variables?: AdminUserProfileQueryVariables,
  options?: UseQueryOptions<AdminUserProfileQuery, TError, TData>
) => {
  return useQuery<AdminUserProfileQuery, TError, TData>(
    variables === undefined ? ['AdminUserProfile'] : ['AdminUserProfile', variables],
    fetchGql<AdminUserProfileQuery, AdminUserProfileQueryVariables>(AdminUserProfileDocument, variables),
    options
  )
}

useAdminUserProfileQuery.getKey = (variables?: AdminUserProfileQueryVariables) =>
  variables === undefined ? ['AdminUserProfile'] : ['AdminUserProfile', variables]
