import { GqlApiError, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { InertHealthcheckItem, TextField } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { getNextWeekdayDate } from '../../../utils/dateUtils'
import { dateFormat, dateStringFormat } from '../../../utils/formatters/dateFormat'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardIssuingStatus } from '../../graphql/generated.types'
import { useOrg } from '../../orgDashboard/organization/useOrg'
import {
  SetLaunchDateMutation,
  SetLaunchDateMutationVariables,
  useSetLaunchDateMutation,
} from './gql/setLaunchDate_gen'
import { useProvisionalOrgQuery } from './useQueryProvisionalOrg'

type SetLaunchDateApiError = GqlApiError<SetLaunchDateMutationVariables>

const SetLaunchDateInput = ({ org }: { org: OrganizationDetailFragment }) => {
  const [launchDateInputValue, setLaunchDateInputValue] = React.useState(org.commutingLaunchDate)
  const mutationOptions = useMutationOptionsWithInvalidation(
    useMutationNotifier<SetLaunchDateMutation, SetLaunchDateApiError, SetLaunchDateMutationVariables>(),
    [useProvisionalOrgQuery.getKey(), useOrg.getKey({ orgId: org.id })]
  )
  const { mutate: setLaunchDate, isLoading: disabled } = useSetLaunchDateMutation(mutationOptions)

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setLaunchDateInputValue(value)
    setLaunchDate({ input: { orgId: org.id, commutingLaunchDate: value } })
  }

  const defaultDate = launchDateInputValue || dateFormat.inputVal(getNextWeekdayDate(1))

  return (
    <TextField
      type="date"
      size="small"
      error={!launchDateInputValue}
      value={defaultDate}
      disabled={disabled}
      inputProps={{ min: dateFormat.inputVal(new Date()) }}
      onChange={handleChange}
    />
  )
}

type LaunchDateHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const LaunchDateHealthcheck = ({ org, todo }: LaunchDateHealthcheckProps) => {
  if (!org) return null
  const onboardingState = org.onboardingState
  if (onboardingState.isAlreadyLaunched) {
    return null
  }

  const inactiveAliceCardStatuses = [AliceCardIssuingStatus.Enabled, AliceCardIssuingStatus.Deactivated]
  // show this if the org does not have a company account, if they have one that has been deactivated for some reason,
  // or if it is 'not_submitted' or 'submitted' - and keep it open for future statuses like 'denied' or 'alice hold'
  const aliceCardProgramNotReady =
    !inactiveAliceCardStatuses.includes(org.aliceCardProgram.cardIssuingStatus) &&
    !onboardingState.isAliceCardProgramActive

  // It's difficult to be certain of feasible launch date before completing other tasks, so we
  // disable this item until the other tasks are complete.
  const maySetLaunchDate =
    !onboardingState.isPayrollConnectionComplete ||
    !onboardingState.isBillingConnected ||
    aliceCardProgramNotReady

  if (!org.commutingLaunchDate) {
    return (
      <InertHealthcheckItem
        primary="Choose launch date"
        secondary="The date on which you'd like employees to receive enrollment notices and begin enrollment in Alice"
        status={maySetLaunchDate ? 'incomplete' : 'disabled'}
        action={<SetLaunchDateInput org={org} />}
        todo={todo}
      />
    )
  }
  if (onboardingState.isLaunchingToday) {
    const launchDayNotice = 'Now your employees can use Alice for commuting benefits'
    return (
      <InertHealthcheckItem
        primary="Launch date set"
        secondary={launchDayNotice}
        status="complete"
        todo={todo}
      />
    )
  }
  if (onboardingState.isLaunchingInFuture && org.commutingLaunchDate) {
    const futureLaunchNotice = `Starting on ${dateFormat.fullDay(dateStringFormat.dateFromDateString(org.commutingLaunchDate))}, your employees will receive enrollment notices.`
    return (
      <InertHealthcheckItem
        primary="Launch date set"
        secondary={futureLaunchNotice}
        status="complete"
        action={<SetLaunchDateInput org={org} />}
        todo={todo}
      />
    )
  }
  return null // don't need to show this item _after_ launch date, although we might want to check that 'launch' happened, if possible
}
