import { ActionLayout, Button, Container, Grid, Link, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { useEmployeeEnrollmentInfoQuery } from '../../enrollment/gql/employeeEnrollmentInfo_gen'
import { EmployeeEnrollmentRequirementStatus } from '../../graphql/generated.types'
import { PageBody } from '../../routes/PageBody'
import { AliceCardConnectCard } from './alice/AliceCardConnectCard'
import { BankingConnectionConnectCard } from './personal/BankingConnectionConnectCard'

/**
 * Basic responsive layout linking to card connect UX
 */
export const CardConnectionsList = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <AliceCardConnectCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <BankingConnectionConnectCard />
      </Grid>
    </Grid>
  )
}

const FinishCardConnectButton = () => {
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      component={Link}
      to="/"
      data-testid="finish-card-connect"
    >
      <FormattedMessage id="common.continue" />
    </Button>
  )
}

export const CardsIndex = () => {
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()

  const employee = employeeEnrollmentData?.employee
  const requirements = employee?.enrollmentInfo.requirements

  // Enrolling users without a spending connection are sent to the Alice Card connection flow
  if (requirements?.spendingConnection === EmployeeEnrollmentRequirementStatus.Incomplete) {
    return <Navigate to="/cards/alice" replace />
  }

  return (
    <PageBody>
      <Container>
        <Typography gutterBottom>
          <FormattedMessage id="enroll.summary.start_spending" />
        </Typography>

        <CardConnectionsList />

        <ActionLayout primary={<FinishCardConnectButton />} />
      </Container>
    </PageBody>
  )
}
