import { Box, Container, Grid, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { SwitchLanguage } from '../../../translations/LocalizationProvider'
import { PageBody } from '../../routes/PageBody'
import { useAliceCardStatus } from '../cards/useQuerySpendingConnections'
import { AddressForm } from './address/AddressForm'
import { EmployeeDocumentList } from './documents/DocumentList'

export const ProfileIndex = () => {
  const hasAliceCards = useAliceCardStatus()
  return (
    <PageBody>
      <Box mb={4} component={Container}>
        <Typography variant="h1">
          <FormattedMessage id="profile.heading" />
        </Typography>
        <Typography gutterBottom>
          <FormattedMessage id="profile.index.what_can_be_managed" />
        </Typography>
      </Box>

      {hasAliceCards && (
        <Box mb={4} component={Container}>
          <AddressForm>
            <Typography gutterBottom>
              <FormattedMessage id="profile.address.note_billing_address" />
            </Typography>
          </AddressForm>
        </Box>
      )}

      <Box mb={4} component={Container}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <SwitchLanguage />
          </Grid>
        </Grid>
      </Box>
      <Container>
        <Typography variant="h2">
          <FormattedMessage id="profile.index.documents" />
        </Typography>
        <EmployeeDocumentList />
      </Container>
    </PageBody>
  )
}
