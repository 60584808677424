import {
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import { PaymentMethodFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { AddNewACHPaymentSource, AddNewStripePaymentMethod } from './AddNewPaymentMethod'
import { isConnectable } from './fundingUtils'
import { TypedBankableEntity } from './types'
import { useConnectPaymentMethod } from './useConnectPaymentMethod'

type ConnectablePaymentMethodListProps = {
  connectablePaymentMethods: Array<PaymentMethodFragment>
  entity: TypedBankableEntity
  onSuccess?: () => void
}

type ConnectablePaymentMethodItemProps = {
  method: PaymentMethodFragment
  connectPaymentMethod: (args: { paymentMethodId: number }) => void
}

const ConnectablePaymentMethodItem = ({
  method,
  connectPaymentMethod,
}: ConnectablePaymentMethodItemProps) => {
  const { id: paymentMethodId } = method
  if (!paymentMethodId) return null
  return (
    <ListItem key={paymentMethodId} disablePadding disableGutters>
      <ListItemButton onClick={() => connectPaymentMethod({ paymentMethodId })}>
        <ListItemIcon>
          <AddIcon color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" fontWeight="bold" color="primary" width="100%">
              {method.descriptor}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}

const ConnectablePaymentMethodList = ({
  connectablePaymentMethods,
  entity,
  onSuccess,
}: ConnectablePaymentMethodListProps) => {
  const { mutate: connectPaymentMethod } = useConnectPaymentMethod({ owner: entity }, { onSuccess })
  return (
    <Paper sx={{ mb: 2 }}>
      <List disablePadding>
        {(connectablePaymentMethods || []).map((method) => (
          <ConnectablePaymentMethodItem
            key={method.id}
            method={method}
            connectPaymentMethod={connectPaymentMethod}
          />
        ))}
      </List>
    </Paper>
  )
}

type ConnectPaymentMethodProps = {
  entity: TypedBankableEntity
  availablePaymentMethods: Array<PaymentMethodFragment> | undefined | null
  currentPaymentMethod: PaymentMethodFragment | undefined
  onSuccess?: () => void
  mayConnectToStripe: boolean
}

export const ConnectPaymentMethod = ({
  entity,
  availablePaymentMethods,
  currentPaymentMethod,
  onSuccess,
  mayConnectToStripe,
}: ConnectPaymentMethodProps) => {
  const connectablePaymentMethods = (availablePaymentMethods || []).filter(
    isConnectable(currentPaymentMethod)
  )

  return (
    <>
      <Container>
        {(connectablePaymentMethods || []).length > 0 && (
          <>
            <ConnectablePaymentMethodList
              connectablePaymentMethods={connectablePaymentMethods}
              entity={entity}
              onSuccess={onSuccess}
            />
            <Typography textAlign="center" fontWeight="bold">
              or
            </Typography>
          </>
        )}
      </Container>
      <Container sx={{ my: 2 }}>
        {mayConnectToStripe ? (
          <AddNewStripePaymentMethod
            currentPaymentMethod={currentPaymentMethod}
            onSuccess={onSuccess}
            entity={entity}
          />
        ) : (
          <AddNewACHPaymentSource
            entity={entity}
            onSuccess={onSuccess}
            currentPaymentMethod={currentPaymentMethod}
          />
        )}
      </Container>
    </>
  )
}
