import { PageBody as _PageBody, BoxProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../employeeDashboard/ContactSupport'
import { useOptionalOrgId } from '../orgDashboard/useOrgId'
import { orgRootPath } from '../orgDashboard/organization/useOrg'
import { Breadcrumbs } from './Breadcrumb'

export const PageBody = ({ children, ...boxProps }: BoxProps) => {
  const orgId = useOptionalOrgId()
  return (
    <_PageBody {...boxProps}>
      {orgId && <Breadcrumbs root={{ route: orgRootPath(orgId), label: 'Home' }} type="admin" />}
      {children}
      <ContactSupport admin={Boolean(orgId)} />
    </_PageBody>
  )
}
