import { HealthcheckItem, InertHealthcheckItem, InertHealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation } from 'react-router'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { AliceCardKycStatus } from '../../graphql/generated.types'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'
import { useCurrentOrgRootPath } from '../organization/useOrg'
import { isOrgFundingConnected } from './funding/fundingUtils'

const ItemDisplay = ({ subpath, ...props }: InertHealthcheckItemProps & { subpath?: string }) => {
  const location = useLocation()
  const orgRootPath = useCurrentOrgRootPath()
  const path = `${orgRootPath}/alice-card-program${subpath || ''}`
  if (['pending', 'disabled'].includes(props.status) || location.pathname === path) {
    return <InertHealthcheckItem {...props} todo />
  }
  return <HealthcheckItem {...props} to={path} todo />
}

type AliceCardProgramHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const AliceCardProgramHealthcheck = ({ org, todo }: AliceCardProgramHealthcheckProps) => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  if (!mayManageAliceCardProgram) return null
  if (!org) return null
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  const fundingConnected = isOrgFundingConnected(org)
  // const fundingActuallyConnected = org.aliceCardProgram.hasFundingConnected
  const hasUnifiedBanking = Boolean(org.aliceCardProgram.hasUnifiedBanking || org.employers.length === 1)

  return (
    <>
      {kycComplete ? (
        <ItemDisplay todo status="complete" primary="Company Representative information complete" />
      ) : (
        <ItemDisplay
          todo
          status="incomplete"
          primary="Add Company Representative information"
          secondary="Information required for setting up contributions account"
          subpath="/company-representative"
        />
      )}
      {fundingConnected ? (
        <ItemDisplay
          status="complete"
          primary={hasUnifiedBanking ? 'Contributions Account connected' : 'Contributions Accounts connected'}
        />
      ) : (
        <ItemDisplay
          todo={todo}
          status="incomplete"
          primary={todo ? 'Add Contributions Account' : 'Contributions Account not connected'}
          secondary="Account must be connected to fund pretax benefits"
          subpath="/account/setup"
        />
      )}
    </>
  )
  if (!fundingConnected) {
    return <></>
  }
  return (
    <ItemDisplay
      status="complete"
      primary={hasUnifiedBanking ? 'Contributions Account connected' : 'Contributions Accounts connected'}
      secondary="Alice Card issuing is active"
    />
  )
}
