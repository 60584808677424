import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  DialogActions,
  Divider,
  FullDialog,
  Grid,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import { Control, SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { usStates } from '../../../data/usStates'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocationFragment } from '../../graphql/fragments/WorkLocationFragment_gen'
import { useOrg } from '../organization/useOrg'
import { convertPlaceToLocationInput, convertWorkLocationToFormInputs } from './locationUtils'
import { LogoInput } from './LogoInput'
import { PlaceSearchBar } from './PlaceSearchBar'
import { WorkLocationFormInputs } from './types'
import { useDeleteWorkLocation } from './useDeleteWorkLocation'
import { useManageWorkLocation } from './useManageWorkLocation'

const ManualAddressInput = ({ control }: { control: Control<WorkLocationFormInputs> }) => {
  const intl = useIntl()
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldController
          name="address.line1"
          control={control}
          label="Address Line 1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldController
          name="address.line2"
          control={control}
          label="Address Line 2"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldController
          name="address.cityName"
          control={control}
          label="City"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldController
          name="address.zipcode"
          control={control}
          label="Zip Code"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldController
          name="address.stateCode"
          control={control}
          rules={{ required: intl.formatMessage({ id: 'common.validation.required' }) }}
          select
          SelectProps={{
            native: true,
          }}
          label="State"
          variant="outlined"
          fullWidth
        >
          <option value=""></option>
          {usStates.map(({ code }) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </TextFieldController>
      </Grid>
    </Grid>
  )
}

type WorkLocationFormProps = {
  org: OrganizationDetailFragment
  location?: WorkLocationFragment
  disableLookup?: boolean
}

const useWorkLocationForm = (
  org: OrganizationDetailFragment,
  location?: WorkLocationFragment,
  onClose?: () => void
) => {
  const locationId = location?.id
  const defaultValues = React.useMemo(() => convertWorkLocationToFormInputs(location), [location]) // converts a WorkLocation to a WorkLocationFormInputs
  const form = useForm<WorkLocationFormInputs>({ defaultValues })
  const { handleSubmit, setValue, reset } = form

  const { mutateAsync: manageWorkLocation } = useManageWorkLocation(org, [useOrg.getKey({ orgId: org.id })], {
    onSuccess: onClose,
  })
  const handleAddressSelect = (place: google.maps.places.PlaceResult) => {
    const locationInput = convertPlaceToLocationInput(place)
    if (!locationInput || !locationInput.address) return

    setValue('address', locationInput.address)
    locationInput.latitude && setValue('latitude', locationInput.latitude)
    locationInput.longitude && setValue('longitude', locationInput.longitude)
    setValue('name', locationInput.name)
  }

  const onSubmit: SubmitHandler<WorkLocationFormInputs> = (values) =>
    manageWorkLocation(values).then(() => reset())

  return {
    handleAddressSelect,
    onSubmit: handleSubmit(onSubmit),
    locationId,
    ...form,
  }
}

export const EditWorkLocationButton = ({ org, location, disableLookup }: WorkLocationFormProps) => {
  const locationCount = org.workLocations.length
  const displayName = location?.name
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const handleEditClick = () => setIsDialogOpen(true)
  const handleDialogClose = () => setIsDialogOpen(false)

  const { control, handleAddressSelect, onSubmit, locationId, setValue, watch } = useWorkLocationForm(
    org,
    location,
    handleDialogClose
  )
  const { mutate: deleteLocation } = useDeleteWorkLocation({ onSuccess: handleDialogClose })

  const logoFileValue = watch('logo')
  const onLogoChange = (logo: File | null) => setValue('logo', logo)
  return (
    <>
      {locationId ? (
        <Button variant="outlined" size="small" onClick={handleEditClick}>
          Edit
        </Button>
      ) : (
        <Button variant="outlined" color="primary" onClick={handleEditClick} startIcon={<AddIcon />}>
          {locationCount > 0 ? 'Add another location' : 'Add a location'}
        </Button>
      )}
      <FullDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        title={<Typography variant="h2">{locationId ? displayName : 'Add a location'}</Typography>}
      >
        <form onSubmit={onSubmit}>
          <Container>
            <Box pb={3}>
              <Grid item xs={12}>
                <LogoInput org={org} location={location} onChange={onLogoChange} fileValue={logoFileValue} />
                <Divider sx={{ my: 3 }} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {!disableLookup && (
                    <Grid item xs={12}>
                      <PlaceSearchBar onSuccess={handleAddressSelect} />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {locationId ? (
                      <ManualAddressInput control={control} />
                    ) : (
                      <Accordion expanded={disableLookup}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="body2" color="primary" fontWeight="bold">
                            Enter address manually
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ManualAddressInput control={control} />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextFieldController
                  name="name"
                  control={control}
                  label="Location name"
                  variant="outlined"
                  autoComplete="off"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
          <DialogActions sx={{ mt: 3 }}>
            <Grid container justifyContent="space-between" flexDirection="row-reverse">
              <Button type="reset" variant="outlined" color="secondary" onClick={handleDialogClose}>
                Cancel
              </Button>
              {locationId && (
                <Button
                  color="error"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this location?')) {
                      deleteLocation({ workLocationId: locationId })
                    }
                  }}
                >
                  Delete location
                </Button>
              )}
            </Grid>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </FullDialog>
    </>
  )
}
