import { Container, ContainerProps, Paper, styled, Theme, useMediaQuery } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { REDIRECT_PARAM } from '../routes/routeUtils'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'

const FormContainer = styled(Container)(({ theme }) => ({
  // a bit of extra vertical padding for this one-off layout
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(3),
  maxWidth: '30rem !important',
}))
export const EmbeddedAuthFormLayout = ({ children, ...props }: ContainerProps) => {
  const isMobileWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  return (
    <FormContainer {...props}>
      <Paper elevation={isMobileWidth ? 0 : 2} sx={{ padding: isMobileWidth ? '0.5em' : '2em' }}>
        {children}
      </Paper>
    </FormContainer>
  )
}

type AuthFormLayoutProps = {
  children: React.ReactNode
}
/**
 * This component is used to wrap the authentication forms.
 * it assumes that the user is not authenticated and will redirect to the redirect URL when they are authenticated.
 */
export const AuthFormLayout = ({ children }: AuthFormLayoutProps) => {
  const { data: currentUserData } = useCurrentUserQuery()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const redirect = searchParams.get(REDIRECT_PARAM) || '/'
  const isAuthenticated = Boolean(currentUserData?.currentUser?.id)

  // this currently doesn't handle querystring params in the redirect
  if (isAuthenticated && location.pathname !== redirect) {
    return <Navigate to={redirect} replace />
  }

  return <EmbeddedAuthFormLayout>{children}</EmbeddedAuthFormLayout>
}
