import * as Types from '../generated.types'

import { AddressFragmentDoc } from './AddressFragment_gen'
export type WorkLocationFragment = {
  __typename?: 'WorkLocation'
  id: number
  name: string
  latitude?: number | null
  longitude?: number | null
  logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
  enrollmentMaterials?: { __typename?: 'EnrollmentMaterials'; zippedMaterialsUrl?: string | null } | null
  address?: {
    __typename?: 'AddressDisplay'
    isVerified: boolean
    isMailing: boolean
    line1?: string | null
    line2?: string | null
    cityName?: string | null
    stateCode?: string | null
    zipcode?: string | null
  } | null
}

export const WorkLocationFragmentDoc = `
    fragment WorkLocation on WorkLocation {
  id
  name
  latitude
  longitude
  logo {
    url
    thumbnailUrl
  }
  enrollmentMaterials {
    zippedMaterialsUrl
  }
  address {
    ...Address
  }
}
    `
