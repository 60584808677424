import { Box } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { matchPath, Navigate, Outlet, useLocation, useMatch } from 'react-router'
import { AvatarMenu } from '../user/AvatarMenu'
import { useIsDemoUser } from '../user/useIsDemoUser'
import { useInactiveEmployerRedirect } from './useEnrollmentEmployer'
import { EnrollmentCompletionStatus, useEnrollmentStatus } from './useEnrollmentStatus'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'

type AllowedPaths = [string, ...Array<string>]
type DefaultPath = string
/**
 * This is a map from the EnrollmentCompletionStatus provided by `useEnrollmentStatus`
 * to a tuple of
 *
 * [
 *   AllowedPaths, // paths that are allowed for the user's current enrollment status
 *   DefaultPath   // the path to redirect to if the user is not on an allowed path
 * ]
 */
const getEnrollmentRedirect = (enrollmentStatus: EnrollmentCompletionStatus): [AllowedPaths, DefaultPath] => {
  // More about `must_reenroll_via_spending_connection` and `must_reenroll_by_confirming`
  // in `useReenrollmentStatus.ts`
  switch (enrollmentStatus) {
    case 'active':
    case 'ineligible':
      // enrollment paths should not be accessed - go to dashboard
      return [['/', '/enroll/summary'], '/']
    case 'must_reenroll_via_spending_connection':
    case 'phone_verified':
      // no spending connections yet, so go to cards
      return [['/cards/*'], '/cards']
    case 'spending_connected':
      return [['/cards/*', '/enroll/summary'], '/enroll/summary']
    case 'must_reenroll_by_confirming':
      // spending connections set, just need to confirm intention to re-enroll
      return [['/enroll/reenroll'], '/enroll/reenroll']
    case 'registered': // enrollment landing should send to registration page for phone verification
    case 'not_started':
      return [['/enroll/*'], '/enroll']
  }
}

/**
 * If the user has not completed enrollment requirements, we need to send them to the appropriate
 * location when they land on the enrollment landing page.
 *
 * `undefined` === redirect has not been determined yet
 * String === path of recommended redirect
 * `null` === no redirect recommended
 */
const useEnrollmentRedirect = () => {
  const enrollmentStatus = useEnrollmentStatus()
  useInactiveEmployerRedirect()
  const isDemo = useIsDemoUser()
  const [allowedPaths = [], entryPath] = enrollmentStatus ? getEnrollmentRedirect(enrollmentStatus) : []
  const currentLocation = useLocation()
  const isOnAllowedPath = Boolean(allowedPaths.find((path) => matchPath(path, currentLocation.pathname)))

  if (!enrollmentStatus) return undefined
  if (enrollmentStatus === 'active' && isDemo) return '/spending' // redirect to /spending for demo once user is enrolled

  if (isOnAllowedPath) return null

  return entryPath
}
/**
 * This component wraps enrollment-related routes in order to ensure that the user is sent
 * to the enrollment route that is directly related to their current completion status.
 *
 * _Note_: This router should _only_ be used in enrollment routes
 *
 * In order to use `EnrollmentRouter` outside of the enrollment-related, e.g. to redirect all users
 * to a particular enrollment view from anywhere in the application, `allowedPath` would need to
 * know more about 'unenrolled' paths, which is probably not a good idea.
 *
 * We prevent mis-use by just throwing an error if the router is called from outside `/enroll/*`
 */
export const EnrollmentRouter = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const isInEnrollment = Boolean(useMatch('/enroll/*'))
  if (!isInEnrollment) {
    throw new Error('EnrollmentRouter used outside of enrollment route')
  }

  const enrollmentRedirect = useEnrollmentRedirect()

  if (enrollmentRedirect === undefined) return null

  if (enrollmentRedirect) {
    return <Navigate to={enrollmentRedirect} replace />
  }

  return (
    <>
      {currentUserData?.currentUser && (
        <Box position="absolute" top={0} right={0}>
          <AvatarMenu compact />
        </Box>
      )}
      <Outlet />
    </>
  )
}
