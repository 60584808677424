import { Typography } from '@mui/material'
import * as React from 'react'
import { Navigate } from 'react-router'
import { FeatureAccess, AccessRequirementEnum } from '../graphql/generated.types'
import { NotFound } from '../routes/NotFound'
import { useCurrentUserQuery } from '../user/gql/currentUser_gen'
import { AvailableProfileTypes, UserProfileType, useRequiredProfileType } from '../user/useProfileType'
import { EmbeddedAuthFormLayout } from './AuthFormLayout'
import { PasswordForm } from './PasswordForm'
import { useLoginPath } from './useLoginPath'
import { FormattedMessage } from 'react-intl'

type AuthenticateProps = {
  children: React.ReactNode
  requiredProfile?: UserProfileType | AvailableProfileTypes
  accessCheck?: keyof FeatureAccess
}

/**
 * A component that will redirect to the login page as soon as it
 * determines that the viewer is _not_ logged in.
 *
 * Currently it will still render its children before the user is determined
 * - it is expected that every view can handle its own 'loading'/empty
 * state appropriately
 */
export const Authenticate = ({ children, requiredProfile = 'user', accessCheck }: AuthenticateProps) => {
  const { data: currentUserData, isLoading } = useCurrentUserQuery()
  const requiredProfileOptions: AvailableProfileTypes =
    typeof requiredProfile === 'string' ? [requiredProfile] : requiredProfile
  const requiredProfileType = useRequiredProfileType(...requiredProfileOptions)
  const loginPath = useLoginPath()

  // As soon as we know the user is not authenticated, redirect to login
  if (isLoading || requiredProfileType === undefined) return null

  if (!currentUserData?.currentUser?.id) return <Navigate to={loginPath} replace />
  const accessRequirement = accessCheck
    ? currentUserData?.currentUser?.access[accessCheck]
    : AccessRequirementEnum.Ok

  if (accessRequirement === AccessRequirementEnum.Forbidden) return <NotFound />
  if (!requiredProfileType) return <NotFound /> // could use '403 Forbidden' in this case

  if (accessRequirement === AccessRequirementEnum.Password) {
    return (
      <EmbeddedAuthFormLayout>
        <Typography gutterBottom>
          <FormattedMessage id="auth.access.password_required" />
        </Typography>
        <PasswordForm />
      </EmbeddedAuthFormLayout>
    )
  }

  return <>{children}</>
}
