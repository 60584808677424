import { TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

type TextFieldControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = Omit<
  TextFieldProps,
  'name'
> &
  Pick<ControllerProps<TValues, TName>, 'name' | 'control' | 'rules'> & {
    disableErrorMessage?: boolean
  }
export const TextFieldController = <TValues extends FieldValues, TName extends Path<TValues>>({
  name,
  control,
  rules,
  helperText,
  disableErrorMessage,
  ...props
}: TextFieldControllerProps<TValues, TName>) => (
  <Controller
    render={({ field, formState: { submitCount }, fieldState: { error, isTouched } }) => {
      const isError = Boolean((isTouched || submitCount > 0) && error?.message)
      const id = props.id || name
      return (
        <TextField
          id={id}
          {...props}
          {...field}
          error={isError}
          helperText={(!disableErrorMessage && isError && error?.message) || helperText}
        />
      )
    }}
    name={name}
    control={control}
    rules={rules}
  />
)

type EmailInputControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = Omit<
  TextFieldControllerProps<TValues, TName>,
  'type'
> & { required?: boolean }
/**
 * Thin wrapper around InlineInputController that sets `type="email"` and adds validation
 * for email format - `req
 */
export const EmailInputController = <TValues extends FieldValues, TName extends Path<TValues>>({
  rules,
  ...props
}: EmailInputControllerProps<TValues, TName>) => {
  const intl = useIntl()
  return (
    <TextFieldController<TValues, TName>
      autoComplete="email"
      label={<FormattedMessage id="profile.email_label" />}
      {...props}
      rules={{
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9_.-]+\.[A-Z]{2,}$/i,
          message: intl.formatMessage({ id: 'profile.email.validation.invalid_format' }),
        },
        ...rules,
      }}
    />
  )
}
