import { ActionLayout, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AliceCardKycStatus } from '../../../graphql/generated.types'
import { useOrg } from '../../organization/useOrg'
import { KYCButton } from '../KYC/KYCButton'
import { RequestKYCHelpInfo } from '../KYC/RequestKYCHelpForm'
import { Navigate } from 'react-router'
import { PageBody } from '../../../routes/PageBody'

export const CompanyRepresentativePage = () => {
  const org = useOrg()
  if (!org) return null
  const kycComplete = org.aliceCardProgram.kycStatus === AliceCardKycStatus.Complete
  if (kycComplete) {
    return <Navigate to=".." replace />
  }
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Company Representative information
      </Typography>
      <Typography gutterBottom>
        Federal regulations require information on a Company Representative. The representative must be a US
        resident with a valid SSN.
      </Typography>
      <RequestKYCHelpInfo org={org} />
      <ActionLayout primary={<KYCButton org={org} />} />
    </PageBody>
  )
}
