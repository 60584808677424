import {
  ActionLayout,
  Button,
  ExternalLink,
  Grid,
  TextFieldController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Control, FormState, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { ACHNumbers, ConnectACHFormValues } from './types'
import { useConnectStripeACHForm } from './useConnectACHForm'

type ConnectStripeACHFormProps = {
  clientSecret: string
  onError: () => void
  onSuccess: (achNumbers: ACHNumbers) => void
}

export const ConnectStripeACHForm = ({ clientSecret, onSuccess, onError }: ConnectStripeACHFormProps) => {
  const { control, onSubmit, formState } = useConnectStripeACHForm(clientSecret, {
    onSuccess,
    onError,
  })

  if (!onSubmit) return null

  return <ConnectACHForm control={control} onSubmit={onSubmit} formState={formState} />
}

type ConnectACHPaymentSourceFormProps = {
  onSuccess: (achNumbers: ACHNumbers) => void
}
export const ConnectACHPaymentSourceForm = ({ onSuccess }: ConnectACHPaymentSourceFormProps) => {
  const { control, handleSubmit, formState } = useForm<ConnectACHFormValues>()
  const onSubmit = handleSubmit(onSuccess)
  return <ConnectACHForm control={control} onSubmit={onSubmit} formState={formState} />
}

type ConnectACHFormProps = {
  control: Control<ConnectACHFormValues>
  formState?: FormState<ConnectACHFormValues>
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}
const ConnectACHForm = ({ control, formState, onSubmit }: ConnectACHFormProps) => {
  const intl = useIntl()
  const required = intl.formatMessage({ id: 'common.validation.required' })

  // ACH test numbers
  // 000123456789	110000000	The automatic bank account validation succeeds.
  // 000111111114	110000000	The automatic bank account validation fails.

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={1} alignItems="end" sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="routingNumber"
            label="Routing number"
            required
            fullWidth
            rules={{ required }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            name="accountNumber"
            label="Account number"
            required
            fullWidth
            rules={{ required }}
          />
        </Grid>
      </Grid>
      {formState?.errors.root && (
        <>
          <Typography variant="body2" color="error" gutterBottom>
            We were unable to verify your account information. Please check you entered the numbers correctly.
          </Typography>
          <Typography variant="body2">
            If this problem persists, please reach out to us for support at{' '}
            <ExternalLink href="mailto:support@thisisalice.com">
              <Typography variant="body2" color="inherit" component="span">
                support@thisisalice.com
              </Typography>
            </ExternalLink>
          </Typography>
        </>
      )}
      <Typography variant="caption" component="p" gutterBottom>
        Please remember to whitelist Alice&apos;s ACH IDs with your bank. Alice transfers funds from your
        payroll bank account to your Contributions Account. Please note: these are not payments to Alice,
        Alice, they are employee benefit contributions. Alice&apos;s ACH IDs are <strong>1800948598</strong>,{' '}
        <strong>4270465600</strong>, and <strong>0000004670</strong>.
      </Typography>
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" type="submit" fullWidth>
            <FormattedMessage id="common.confirm" />
          </Button>
        }
      />
    </form>
  )
}
