import { Container } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { useEnrollmentStatus } from '../useEnrollmentStatus'
import { HowAliceWorks } from './HowAliceWorks'
import { StartBar } from './StartBar'

export const EnrollmentLanding = () => {
  const enrollmentStatus = useEnrollmentStatus()
  if (enrollmentStatus === 'registered') return <Navigate to="./register" /> // 'registered' means they haven't verified their phone number yet
  return (
    <Container
      sx={(theme) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('sm')]: {
          paddingBottom: 0,
        },
      })}
    >
      <HowAliceWorks />

      <StartBar />
    </Container>
  )
}
