import { Callout, Link, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { AdminNotificationPreferencesForm } from './AdminNotificationPreferencesForm'
import { useAdminUserProfileQuery } from './gql/adminUserProfile_gen'

const ProfileTypeDisplay = () => {
  const org = useOrg()
  return (
    <Callout variant="body2" gutterBottom>
      You have full administrator access to all paygroups in {org?.name}
      .<br />
      You may manage administrator access for other employees on the{' '}
      <Link to={`/manage/people`}>People page</Link>.
    </Callout>
  )
}

/**
 * The admin profile page currently only manages email preferences for admins
 */
export const AdminProfilePage = () => {
  const { data: adminUserProfileData } = useAdminUserProfileQuery()

  const adminUser = adminUserProfileData?.currentUser
  if (!adminUser) return null

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Email preferences
      </Typography>
      <ProfileTypeDisplay />
      <AdminNotificationPreferencesForm adminUser={adminUser} />
    </PageBody>
  )
}
