import { List } from '@alice-financial/pretext-ui'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import SavingsIcon from '@mui/icons-material/Savings'
import AliceCardIllustration from 'jsx:./assets/alice-card-illustration.svg'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { FeatureItem, FeatureList } from '../FeatureList'
import { CardConnectCardProps, LabelWithChevron, SpendingConnectCard } from '../SpendingConnectCard'
import { useQuerySpendingConnections } from '../useQuerySpendingConnections'
import { AliceCardItems } from './AliceCardItems'
import {
  findActiveCardAccount,
  getAvailableInstantAliceCard,
  getAvailablePhysicalAliceCard,
} from './aliceCardUtils'

const CardRoot = (props: Omit<CardConnectCardProps, 'Card'>) => (
  <SpendingConnectCard data-testid="connect-alice" {...props} Card={AliceCardIllustration} />
)

const NewAliceCardRequest = () => {
  const features: Array<FeatureItem> = [
    { Icon: CreditCardIcon, messageId: 'cards.alice.feature_dont_pay_out_of_pocket' },
    { Icon: DirectionsBusIcon, messageId: 'cards.alice.feature_tap_or_swipe_to_pay' },
    { Icon: SavingsIcon, messageId: 'cards.alice.feature_spend_from_pretax_income' },
  ]

  return (
    <CardRoot
      to="/cards/alice"
      label={
        <LabelWithChevron>
          <FormattedMessage id="connections.all_connections.alice_card.heading" />
        </LabelWithChevron>
      }
    >
      <FeatureList features={features} />
    </CardRoot>
  )
}

export const AliceCardConnectCard = () => {
  const {
    data: { aliceCardAccounts },
  } = useQuerySpendingConnections()
  const activeCardAccount = findActiveCardAccount(aliceCardAccounts)
  const provisioningStatus = activeCardAccount?.cardProvisioningStatus

  const instantCard = activeCardAccount && getAvailableInstantAliceCard(activeCardAccount.aliceCards)
  const physicalCard = activeCardAccount && getAvailablePhysicalAliceCard(activeCardAccount.aliceCards)

  if (!instantCard && !physicalCard && !provisioningStatus?.instant && !provisioningStatus?.physical) {
    return <NewAliceCardRequest />
  }

  return (
    <SpendingConnectCard label={<LabelWithChevron>Alice Card</LabelWithChevron>} to="/cards/alice">
      <List disablePadding>
        <AliceCardItems />
      </List>
    </SpendingConnectCard>
  )
}
