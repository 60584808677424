import { isNotNull } from '../../../../utils/typeUtils'
import {
  AliceCardFundingFragment,
  EmployerSummaryFragment,
  OrganizationDetailFragment,
  PaymentMethodConnectionFragment,
  PaymentMethodFragment,
} from '../../../graphql/fragments/OrganizationFragment_gen'
import { PaymentMethodStatus } from '../../../graphql/generated.types'

export const isCurrentPaymentMethodConnection = (pmc: PaymentMethodConnectionFragment) => pmc.isCurrent

export const getCurrentPaymentMethodConnection = (aliceCardFunding: AliceCardFundingFragment) =>
  (aliceCardFunding.paymentMethodConnections || []).find(isCurrentPaymentMethodConnection)

export const isPaymentMethodActive = (pmc: PaymentMethodConnectionFragment | null | undefined) =>
  Boolean(
    pmc &&
      [PaymentMethodStatus.Active, PaymentMethodStatus.PendingVerification].includes(pmc.paymentMethod.status)
  )
const isPaymentMethodConnected = (paymentMethod: PaymentMethodFragment) =>
  CONNECTED_STATUSES.includes(paymentMethod.status)
export const isConnectionConnected = (pmc: PaymentMethodConnectionFragment | null | undefined) =>
  Boolean(pmc && isPaymentMethodConnected(pmc.paymentMethod))
export const isPaygroupFundingConnected = (paygroup: EmployerSummaryFragment) =>
  isConnectionConnected(getCurrentPaymentMethodConnection(paygroup.aliceCardFunding))
export const isOrgFundingConnected = (org: OrganizationDetailFragment) =>
  org.aliceCardProgram.hasFundingConnected ||
  isConnectionConnected(getCurrentPaymentMethodConnection(org.aliceCardFunding)) ||
  org.employers.filter(isNotNull).every(isPaygroupFundingConnected)

const CONNECTED_STATUSES = [
  PaymentMethodStatus.Active,
  PaymentMethodStatus.PendingVerification,
  PaymentMethodStatus.PendingSetup,
]
const CONNECTABLE_STATUSES = [PaymentMethodStatus.Active, PaymentMethodStatus.PendingVerification]
export const isConnectable =
  (currentPaymentMethod: PaymentMethodFragment | undefined) => (paymentMethod: PaymentMethodFragment) =>
    currentPaymentMethod?.last4 !== paymentMethod.last4 && CONNECTABLE_STATUSES.includes(paymentMethod.status)
