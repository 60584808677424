import { List, Paper } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { AliceCardHealthCheckItem, BankingAcccountsHealthCheckItem } from './AccountsHealthcheckItems'
import { RecentSpendingCheckItem } from './RecentSpendingHealthcheckItem'

/**
 * Simple wrapper around a set of <HealthcheckItem /> elements
 */
export const EmployeeHealthcheck = () => (
  <Paper>
    <List dense disablePadding>
      <RecentSpendingCheckItem />
      <BankingAcccountsHealthCheckItem />
      <AliceCardHealthCheckItem />
    </List>
  </Paper>
)
