import * as Types from '../generated.types'

export type AddressFragment = {
  __typename?: 'AddressDisplay'
  isVerified: boolean
  isMailing: boolean
  line1?: string | null
  line2?: string | null
  cityName?: string | null
  stateCode?: string | null
  zipcode?: string | null
}

export const AddressFragmentDoc = `
    fragment Address on AddressDisplay {
  isVerified
  isMailing
  line1
  line2
  cityName
  stateCode
  zipcode
}
    `
