import * as Types from '../../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreateAchPaymentSourceMutationVariables = Types.Exact<{
  input: Types.CreateAchPaymentSourceInput
}>

export type CreateAchPaymentSourceMutation = {
  __typename?: 'Mutation'
  createAliceCardAchPaymentSource?: {
    __typename?: 'CreateACHPaymentSourcePayload'
    paymentMethodConnection?: {
      __typename?: 'PaymentMethodConnection'
      isCurrent: boolean
      isActive: boolean
    } | null
  } | null
}

export const CreateAchPaymentSourceDocument = `
    mutation CreateACHPaymentSource($input: CreateACHPaymentSourceInput!) {
  createAliceCardAchPaymentSource(input: $input) {
    paymentMethodConnection {
      isCurrent
      isActive
    }
  }
}
    `

export const useCreateAchPaymentSourceMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreateAchPaymentSourceMutation,
    TError,
    CreateAchPaymentSourceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateAchPaymentSourceMutation,
    TError,
    CreateAchPaymentSourceMutationVariables,
    TContext
  >(
    ['CreateACHPaymentSource'],
    (variables?: CreateAchPaymentSourceMutationVariables) =>
      fetchGql<CreateAchPaymentSourceMutation, CreateAchPaymentSourceMutationVariables>(
        CreateAchPaymentSourceDocument,
        variables
      )(),
    options
  )
}
