import { Box, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useParams } from 'react-router'
import { isNotNull } from '../../../utils/typeUtils'
import { FinchConnectResultAlert } from '../../externalServices/finch/FinchConnectResultAlert'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { EngagementSummary } from './EngagementSummary'

const isDigits = (str: string) => /^\d+$/.test(str)

export const EmployerHomepage = () => {
  const params = useParams()
  const org = useOrg()

  // If the employerId is not a number, we are not on the employer homepage
  if (!params.employerId || !isDigits(params.employerId)) return null
  const employer = org?.employers.filter(isNotNull).find((e) => e.id.toString() === params.employerId)
  if (!employer) return null

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom={!employer.marketingFriendlyName}>
        {employer.name}
      </Typography>
      {employer.marketingFriendlyName && (
        <Typography variant="subtitle1" gutterBottom>
          {employer.marketingFriendlyName}
        </Typography>
      )}
      <FinchConnectResultAlert />
      <Typography variant="subtitle1">
        Pay group name: <strong>{employer.name}</strong>
      </Typography>
      <Box my={2}>
        <SectionHeading id="alice-card-program" to="people">
          Employees
        </SectionHeading>
      </Box>
      <EngagementSummary employer={employer} />
    </PageBody>
  )
}
