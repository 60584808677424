import * as Types from '../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrgRolesQueryVariables = Types.Exact<{ [key: string]: never }>

export type OrgRolesQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    roles: Array<{
      __typename?: 'Role'
      id: number
      roleName: Types.RoleName
      resourceDisplayInfo: {
        __typename?: 'RoleResource'
        id: number
        displayName?: string | null
        resourceType: Types.RoleResourceType
      }
    }>
  } | null
  organization?: { __typename?: 'Organization'; id: number } | null
}

export type RoleFragment = {
  __typename?: 'Role'
  id: number
  roleName: Types.RoleName
  resourceDisplayInfo: {
    __typename?: 'RoleResource'
    id: number
    displayName?: string | null
    resourceType: Types.RoleResourceType
  }
}

export const RoleFragmentDoc = `
    fragment Role on Role {
  id
  roleName
  resourceDisplayInfo {
    id
    displayName
    resourceType
  }
}
    `
export const OrgRolesDocument = `
    query OrgRoles {
  currentUser {
    roles(roleType: OrganizationRole) {
      ...Role
    }
  }
  organization {
    id
  }
}
    ${RoleFragmentDoc}`

export const useOrgRolesQuery = <TData = OrgRolesQuery, TError = Error>(
  variables?: OrgRolesQueryVariables,
  options?: UseQueryOptions<OrgRolesQuery, TError, TData>
) => {
  return useQuery<OrgRolesQuery, TError, TData>(
    variables === undefined ? ['OrgRoles'] : ['OrgRoles', variables],
    fetchGql<OrgRolesQuery, OrgRolesQueryVariables>(OrgRolesDocument, variables),
    options
  )
}

useOrgRolesQuery.getKey = (variables?: OrgRolesQueryVariables) =>
  variables === undefined ? ['OrgRoles'] : ['OrgRoles', variables]
