import * as React from 'react'
import { FinchConnectResultAlert } from '../../externalServices/finch/FinchConnectResultAlert'
import { PageBody } from '../../routes/PageBody'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { useOrg } from '../organization/useOrg'
import { AliceCardProgramSection } from './AliceCardProgramSection'
import { EnrollmentSection } from './EnrollmentSection'
import { OrgHealthcheck } from './OrgHealthcheck'

export const OrgHomepage = () => {
  const { data: userData } = useCurrentUserQuery()
  const org = useOrg()
  const user = userData?.currentUser

  if (!user || !org) return null

  return (
    <PageBody maxWidth={800}>
      <OrgHealthcheck org={org} />
      <FinchConnectResultAlert />
      <AliceCardProgramSection org={org} />
      <EnrollmentSection org={org} />
    </PageBody>
  )
}
