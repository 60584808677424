import { Alert, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useParams } from 'react-router'
import { isNotNull } from '../../../utils/typeUtils'
import { useOrg } from '../organization/useOrg'
import { getBillingProblem } from './billingUtils'
import { ConnectBilling } from './ConnectBilling'
import { OrgInvoices } from './OrgInvoices'
import { PaygroupBillingList } from './PaygroupBilling'
import { PageBody } from '../../routes/PageBody'

export const BillingPage = () => {
  const { employerId } = useParams()
  const org = useOrg()
  const hasBillingProblem = getBillingProblem(org)
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Billing
      </Typography>
      {hasBillingProblem && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          Your company&apos;s billing information is missing or out of date. Alice will not process employee
          reimbursements without your updated billing information on file. Please update your billing
          information to avoid disruption to your services.
        </Alert>
      )}
      {employerId ? (
        <PaygroupBillingList
          employers={org.employers
            .filter(isNotNull)
            .filter((employer) => employer?.id.toString() === employerId)}
        />
      ) : (
        <ConnectBilling org={org} />
      )}
      <OrgInvoices org={org} />
    </PageBody>
  )
}
