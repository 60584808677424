import { Button, ButtonProps } from '@alice-financial/pretext-ui'
import UploadIcon from '@mui/icons-material/Upload'
import React from 'react'

type LogoUploadButtonProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size'
> &
  Pick<ButtonProps, 'size' | 'startIcon' | 'variant' | 'color' | 'fullWidth'>

export const _LogoUploadButton = (
  {
    id,
    children,
    startIcon,
    size,
    variant = 'contained',
    color = 'primary',
    fullWidth,
    ...inputProps
  }: LogoUploadButtonProps,

  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <>
      <input
        {...inputProps}
        ref={ref}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
        id={`upload-logo-${id}`}
        type="file"
      />
      <Button
        size={size}
        variant={variant}
        color={color}
        component="label"
        htmlFor={`upload-logo-${id}`}
        sx={{ textWrap: 'nowrap' }}
        startIcon={startIcon || <UploadIcon />}
        fullWidth={fullWidth}
      >
        {children}
      </Button>
    </>
  )
}

export const LogoUploadButton = React.forwardRef(_LogoUploadButton)
