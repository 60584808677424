import { Box, Grid, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { isNotNull } from '../../../utils/typeUtils'
import { PaygroupList } from './PaygroupList'
import { OrgSectionProps } from './types'

export const EnrollmentSection = ({ org }: OrgSectionProps) => {
  if (!org) return null

  const onboardingState = org.onboardingState
  if (!onboardingState.isPayrollConnectionComplete) return null
  if (!onboardingState.isAlreadyLaunched) return null

  const payGroups = org.employers.filter(isNotNull)
  if (payGroups.length === 0) return null

  const { totalSavings } = org.employees

  return (
    <Box my={4}>
      <SectionHeading gutterBottom>Payroll connection</SectionHeading>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            Payroll provider: <strong>{org.payrollConnection.payrollPlatform?.name}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary">
            <strong>{org.employees.enrolledCount}</strong>/{org.employees.reachableCount} enrolled
          </Typography>
        </Grid>
      </Grid>
      {totalSavings ? (
        <Typography variant="body2" color="primary" gutterBottom>
          <strong>{formatCentsAsDollars(totalSavings)}</strong> total savings
        </Typography>
      ) : null}

      <Typography variant="h4">Pay groups</Typography>
      <PaygroupList payGroups={payGroups} />
    </Box>
  )
}
