import * as Types from '../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type CreatePhoneLoginCodeMutationVariables = Types.Exact<{
  input: Types.CreatePhoneLoginCodeInput
}>

export type CreatePhoneLoginCodeMutation = {
  __typename?: 'Mutation'
  createPhoneLoginCode?: {
    __typename?: 'CreatePhoneLoginCodePayload'
    success: boolean
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const CreatePhoneLoginCodeDocument = `
    mutation CreatePhoneLoginCode($input: CreatePhoneLoginCodeInput!) {
  createPhoneLoginCode(input: $input) {
    success
    inputErrors {
      argument
      message
    }
  }
}
    `

export const useCreatePhoneLoginCodeMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<
    CreatePhoneLoginCodeMutation,
    TError,
    CreatePhoneLoginCodeMutationVariables,
    TContext
  >
) => {
  return useMutation<CreatePhoneLoginCodeMutation, TError, CreatePhoneLoginCodeMutationVariables, TContext>(
    ['CreatePhoneLoginCode'],
    (variables?: CreatePhoneLoginCodeMutationVariables) =>
      fetchGql<CreatePhoneLoginCodeMutation, CreatePhoneLoginCodeMutationVariables>(
        CreatePhoneLoginCodeDocument,
        variables
      )(),
    options
  )
}
