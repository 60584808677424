import { TypographyOptions } from '@mui/material/styles/createTypography'
import { palette } from './palette'

export const PRIMARY_FONT_FAMILY = 'system-ui'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5: false
    h6: false
  }
}

const headingColor = palette.primary.main
export const typography: TypographyOptions = {
  fontFamily: `'${PRIMARY_FONT_FAMILY}', -apple-system, sans-serif`,
  h1: {
    color: headingColor,
    fontSize: '2.6rem',
    lineHeight: 1,
    fontWeight: 'bold',
  },
  h2: {
    color: headingColor,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: 1.1,
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: 1.1,
  },
  h4: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: 1.1,
  },
  h5: undefined,
  h6: undefined,
  subtitle1: {
    color: palette.text.secondary,
    lineHeight: 1.1,
  },
  subtitle2: {
    fontWeight: 'bold',
    color: palette.text.disabled,
    lineHeight: 1.1,
  },
  body1: {
    lineHeight: 1.2,
    fontSize: '1.2rem',
  },
  body2: {
    lineHeight: 1.2,
    color: palette.text.secondary,
    fontSize: '1.1rem',
  },
  caption: {
    fontSize: '1rem',
    color: palette.text.secondary,
    lineHeight: 1.2,
  },
  button: {
    fontSize: '1.1rem',
  },
}
