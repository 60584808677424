import { HealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { orgRootPath } from '../../orgDashboard/organization/useOrg'

type OrgLogoHealthcheckProps = {
  org?: OrganizationDetailFragment | null
  todo?: boolean
}
export const OrgLogoHealthcheck = ({ org, todo }: OrgLogoHealthcheckProps) => {
  if (!org) return null
  if (org.onboardingState.isAlreadyLaunched) return null
  const logoRequirementSatisfied =
    org.onboardingState.isLogoPresent ||
    (org.workLocations.length > 0 && org.workLocations.every((location) => location?.logo))

  return logoRequirementSatisfied ? (
    <HealthcheckItem
      primary="Logo uploaded"
      secondary="Enrollment materials will include a logo your employees recognize"
      status="complete"
      to={`${orgRootPath(org.id)}/locations`}
      todo={todo}
    />
  ) : (
    <HealthcheckItem
      primary="Add logo"
      secondary="Enrollment materials need a logo your employees recognize"
      status="incomplete"
      to={`${orgRootPath(org.id)}/locations`}
      todo={todo}
    />
  )
}
