import { Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { PageSizePagingContextProvider } from '../../../utils/paging/pageSizePaging/PageSizePagingContext'
import { PageBody } from '../../routes/PageBody'
import { PayPeriodList } from './PayPeriodList'

export const PayPeriodsPage = () => {
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Pay period reports
      </Typography>

      <PageSizePagingContextProvider>
        <PayPeriodList />
      </PageSizePagingContextProvider>
    </PageBody>
  )
}
