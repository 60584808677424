import {
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  PII,
  useMediaQuery,
  useTheme,
} from '@alice-financial/pretext-ui'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/MoreVert'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { SwitchLanguage } from '../../translations/LocalizationProvider'
import { getFullName } from '../../utils/formatters/userFormat'
import { useIsEnrolling } from '../enrollment/useEnrollmentStatus'
import { useCurrentUserQuery } from './gql/currentUser_gen'
import { AvailableProfileTypes, useCurrentProfileType, useHasProfileType } from './useProfileType'
import { useGetHelp } from '../employeeDashboard/ContactSupport'

const useProfileSwitch = (...requiredProfileTypes: AvailableProfileTypes) => {
  const currentProfileType = useCurrentProfileType()
  const hasRequiredProfileType = useHasProfileType(...requiredProfileTypes)
  if (!currentProfileType) return false

  return hasRequiredProfileType && !requiredProfileTypes.includes(currentProfileType)
}

const ProfileSwitchMenuItem = ({ onClick }: { onClick: () => void }) => {
  const showAdminProfileSwitch = useProfileSwitch('organization_admin')
  const showEmployeeProfileSwitch = useProfileSwitch('employee')
  if (showAdminProfileSwitch) {
    return (
      <MenuItem onClick={onClick} component={Link} to="/manage">
        <FormattedMessage id="menu.switch_to_admin" />
      </MenuItem>
    )
  }
  if (showEmployeeProfileSwitch) {
    return (
      <MenuItem onClick={onClick} component={Link} to="/dashboard">
        <FormattedMessage id="menu.switch_to_employee" />
      </MenuItem>
    )
  }

  return null
}

const GetHelpMenuItem = ({ handleClose }: { handleClose: () => void }) => {
  const { openHelp } = useGetHelp()
  const onClick = () => {
    handleClose()
    openHelp()
  }
  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage id="contact_support.get_help" />
    </MenuItem>
  )
}

/**
 * This is a menu that is intended to be put inside an AppBar/Header component. It displays
 * the user's avatar (or initials) and will show a menu when clicked. This menu will link to
 * the user's profile page and provide a logout link.
 */
export const AvatarMenu = ({ compact }: { compact?: boolean }) => {
  const theme = useTheme()
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const isCompact = compact || isMobileWidth

  const { data: currentUserData } = useCurrentUserQuery()
  const isEnrolling = useIsEnrolling()
  const currentProfileType = useCurrentProfileType()

  const menuAnchor = React.useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleMenu = () => setAnchorEl(menuAnchor.current)
  const handleClose = () => setAnchorEl(null)

  if (!currentUserData?.currentUser) return <SwitchLanguage compact={isCompact} />

  const { currentUser } = currentUserData

  return (
    <>
      {isEnrolling && currentProfileType === 'user' && <SwitchLanguage compact={isCompact} />}
      {isCompact ? (
        <IconButton color="primary" ref={menuAnchor} onClick={handleMenu}>
          <MenuIcon fontSize="large" />
        </IconButton>
      ) : (
        <Button
          variant="text"
          ref={menuAnchor}
          onClick={handleMenu}
          endIcon={<MenuIcon fontSize="large" />}
          sx={{ textWrap: 'nowrap' }}
        >
          <PII>{getFullName(currentUser)}</PII>
        </Button>
      )}
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ProfileSwitchMenuItem onClick={handleClose} />
        {!isEnrolling && (
          <MenuItem onClick={handleClose} component={Link} to="/profile">
            <FormattedMessage id="menu.profile" />
          </MenuItem>
        )}
        {!isEnrolling && (
          <MenuItem onClick={handleClose} component={Link} to="/dashboard/documents">
            <FormattedMessage id="menu.documents" />
          </MenuItem>
        )}
        <GetHelpMenuItem handleClose={handleClose} />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/logout"
          sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '0.5em' }}
        >
          <FormattedMessage id="menu.logout" />
          <LogoutIcon fontSize="small" color="primary" />
        </MenuItem>
      </Menu>
    </>
  )
}
