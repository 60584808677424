import { Box, Button, Card, CardContent, Grid, Typography } from '@alice-financial/pretext-ui'
import DownloadIcon from '@mui/icons-material/Download'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'
import { isNotEmpty, isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocationFragment } from '../../graphql/fragments/WorkLocationFragment_gen'
import { EditWorkLocationButton } from './WorkLocationForm'
type WorkLocationItemProps = {
  org: OrganizationDetailFragment
  location: WorkLocationFragment
}

export const WorkLocationItem: React.FC<WorkLocationItemProps> = ({ org, location }) => {
  const { line1, line2, cityName, stateCode, zipcode } = location.address || {}
  const enrollmentMaterialsZipUrl = location?.enrollmentMaterials?.zippedMaterialsUrl
  const displayName = location.name
  const displayLogo = location.logo?.url || org.logo?.url
  const stateAndZip = [stateCode, zipcode].filter(isNotNull).join(' ')
  const addressLine = [line1 !== displayName ? displayName : null, line1, line2, cityName, stateAndZip]
    .filter(isNotEmpty)
    .join(', ')

  return (
    <Card component={Grid} container justifyContent="space-between">
      <CardContent component={Grid} container item xs={8} flexWrap="nowrap">
        <Grid item width={32}>
          <LocationOnIcon color="primary" />
        </Grid>
        <Grid item container flexDirection="column" justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">{displayName}</Typography>
            <Typography variant="caption" component="p" gutterBottom>
              {addressLine}
            </Typography>
          </Grid>
          <Grid container columnGap={1}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<DownloadIcon />}
              component="a"
              href={enrollmentMaterialsZipUrl || '#'}
              download
            >
              Download materials
            </Button>
            <EditWorkLocationButton org={org} location={location} disableLookup />
          </Grid>
        </Grid>
      </CardContent>
      {displayLogo && (
        <Grid
          container
          item
          xs={4}
          flexWrap="nowrap"
          alignSelf="stretch"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: 'rgba(128, 128, 128, 0.1)' }}
        >
          <Box
            component="img"
            src={displayLogo}
            alt={`Logo for ${displayName}`}
            maxHeight={100}
            maxWidth="90%"
            mx="5%"
            sx={{
              boxShadow: (theme) => theme.shadows[1],
            }}
          />
        </Grid>
      )}
    </Card>
  )
}
