import { Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { PageBody } from '../../routes/PageBody'
import { useOrg } from '../organization/useOrg'
import { WorkLocationList } from './WorkLocationList'

export const WorkLocationsPage = () => {
  const org = useOrg()
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Locations</Typography>

      <Typography variant="body2" gutterBottom>
        Where do your employees work? Let us know so that we can create printable enrollment materials.
      </Typography>
      <WorkLocationList org={org} />
    </PageBody>
  )
}
