import { Button, FormHelperText, Grid } from '@alice-financial/pretext-ui'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import UploadIcon from '@mui/icons-material/Upload'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { WorkLocationFragment } from '../../graphql/fragments/WorkLocationFragment_gen'
import { LogoUploadButton } from '../LogoUploadButton'

type LogoFormProps = {
  onChange: (logo: File | null) => void
  fileValue: File | null | undefined
  org: OrganizationDetailFragment
  location: WorkLocationFragment | null | undefined
}
export const LogoInput = ({ onChange, fileValue, org, location }: LogoFormProps) => {
  const logoValueUrl = fileValue ? URL.createObjectURL(fileValue) : fileValue
  const orgLogo = org.logo?.url || null
  const displayLogo =
    logoValueUrl === null // location logo was intentionally cleared - fall back to default
      ? orgLogo || null
      : logoValueUrl || location?.logo?.url || orgLogo || null // location logo has not been changed, so use it or persisted values
  const orgHasMultipleLocations = org.workLocations?.length > 1

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files?.item(0) || null)
  }
  const removeLogo = () => onChange(null)
  const isDefaultLogo = displayLogo === orgLogo

  if (!displayLogo) {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <LogoUploadButton
            onChange={handleFileChange}
            fullWidth
            startIcon={<UploadIcon />}
            variant="contained"
            color="primary"
          >
            Upload logo
          </LogoUploadButton>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormHelperText>
            This logo will be used on enrollment materials for your employees to help them sign up for
            benefits.
          </FormHelperText>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <img src={displayLogo} alt="Logo" style={{ maxWidth: 360, maxHeight: 120 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container flexDirection="row-reverse" spacing={1}>
          <Grid item>
            <LogoUploadButton
              onChange={handleFileChange}
              fullWidth
              startIcon={displayLogo ? <SwapHorizIcon /> : <UploadIcon />}
              size="small"
              variant="outlined"
              color="primary"
            >
              Change {orgLogo && orgHasMultipleLocations ? 'logo for this location' : 'logo'}
            </LogoUploadButton>
          </Grid>
          {!isDefaultLogo && orgLogo && (
            <Grid item>
              <Button color="secondary" size="small" onClick={removeLogo} fullWidth>
                Use default logo
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
