import * as Types from '../../../graphql/generated.types'

import { AddressFragmentDoc } from '../../../graphql/fragments/AddressFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type ProvisionalOrgsQueryVariables = Types.Exact<{ [key: string]: never }>

export type ProvisionalOrgsQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: number
    provisionalOrganizations: Array<{
      __typename?: 'ProvisionalOrganization'
      id: number
      legalName: string
      payrollPlatform?: { __typename?: 'PayrollPlatform'; id: number; name: string; code: string } | null
      paySchedule?: {
        __typename?: 'PaySchedule'
        payrollFrequency: Types.PayrollFrequency
        nextPayday: string
        lastDayOfPayPeriod: string
        lastDayOfMonth?: boolean | null
        twiceMonthlyType?: Types.TwiceMonthlyType | null
        twiceMonthlyPayday1?: string | null
        twiceMonthlyPayday2?: string | null
      } | null
      address?: {
        __typename?: 'AddressDisplay'
        isVerified: boolean
        isMailing: boolean
        line1?: string | null
        line2?: string | null
        cityName?: string | null
        stateCode?: string | null
        zipcode?: string | null
      } | null
      organization?: {
        __typename?: 'Organization'
        id: number
        onboardingState: { __typename?: 'OnboardingState'; isDoneWithOnboardingDocuments: boolean }
        employers: Array<{ __typename?: 'Employer'; id: number } | null>
      } | null
    }>
  } | null
}

export type ProvisionalOrgFragment = {
  __typename?: 'ProvisionalOrganization'
  id: number
  legalName: string
  payrollPlatform?: { __typename?: 'PayrollPlatform'; id: number; name: string; code: string } | null
  paySchedule?: {
    __typename?: 'PaySchedule'
    payrollFrequency: Types.PayrollFrequency
    nextPayday: string
    lastDayOfPayPeriod: string
    lastDayOfMonth?: boolean | null
    twiceMonthlyType?: Types.TwiceMonthlyType | null
    twiceMonthlyPayday1?: string | null
    twiceMonthlyPayday2?: string | null
  } | null
  address?: {
    __typename?: 'AddressDisplay'
    isVerified: boolean
    isMailing: boolean
    line1?: string | null
    line2?: string | null
    cityName?: string | null
    stateCode?: string | null
    zipcode?: string | null
  } | null
  organization?: {
    __typename?: 'Organization'
    id: number
    onboardingState: { __typename?: 'OnboardingState'; isDoneWithOnboardingDocuments: boolean }
    employers: Array<{ __typename?: 'Employer'; id: number } | null>
  } | null
}

export const ProvisionalOrgFragmentDoc = `
    fragment ProvisionalOrg on ProvisionalOrganization {
  id
  legalName
  payrollPlatform {
    id
    name
    code
  }
  paySchedule {
    payrollFrequency
    nextPayday
    lastDayOfPayPeriod
    lastDayOfMonth
    twiceMonthlyType
    twiceMonthlyPayday1
    twiceMonthlyPayday2
  }
  address {
    ...Address
  }
  organization {
    id
    onboardingState {
      isDoneWithOnboardingDocuments
    }
    employers {
      id
    }
  }
}
    `
export const ProvisionalOrgsDocument = `
    query ProvisionalOrgs {
  currentUser {
    id
    provisionalOrganizations {
      ...ProvisionalOrg
    }
  }
}
    ${ProvisionalOrgFragmentDoc}
${AddressFragmentDoc}`

export const useProvisionalOrgsQuery = <TData = ProvisionalOrgsQuery, TError = Error>(
  variables?: ProvisionalOrgsQueryVariables,
  options?: UseQueryOptions<ProvisionalOrgsQuery, TError, TData>
) => {
  return useQuery<ProvisionalOrgsQuery, TError, TData>(
    variables === undefined ? ['ProvisionalOrgs'] : ['ProvisionalOrgs', variables],
    fetchGql<ProvisionalOrgsQuery, ProvisionalOrgsQueryVariables>(ProvisionalOrgsDocument, variables),
    options
  )
}

useProvisionalOrgsQuery.getKey = (variables?: ProvisionalOrgsQueryVariables) =>
  variables === undefined ? ['ProvisionalOrgs'] : ['ProvisionalOrgs', variables]
