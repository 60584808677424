import { ActionLayout, Button, CheckboxController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { NotificationChannel, NotificationPreference, NotificationType } from '../../graphql/generated.types'
import { AdminUserProfileFragment } from './gql/adminUserProfile_gen'
import { useUpdateNotificationPreferences } from './useUpdateNotificationPreferences'

const NOTIF_LABELS: Record<NotificationType, string> = {
  [NotificationType.MonthlyFinance]:
    'Monthly finance review of invoices, balances, withdrawals, and deductions for the prior month.',
  [NotificationType.InvoiceEvents]: 'Email for each invoice.',
  [NotificationType.PbaWithdrawal]:
    'Email for each payroll bank account withdrawal of employee contributions.',
}

type FormValues = Partial<Record<NotificationType, boolean>>
const apiToForm = (notificationPreferences: AdminUserProfileFragment['notificationPreferences']) => {
  return notificationPreferences.reduce<FormValues>((acc, { notificationType, enabled }) => {
    acc[notificationType] = enabled
    return acc
  }, {})
}
const formToApi = (formValues: FormValues) => ({
  notificationPreferences: Object.entries(formValues).map(([notificationType, enabled]) => ({
    notificationType: notificationType as NotificationType,
    channel: NotificationChannel.Email,
    enabled,
  })),
})

type SortableNotificationPreference = Pick<NotificationPreference, 'notificationType' | 'channel'>
const prefSort = (a: SortableNotificationPreference, b: SortableNotificationPreference) => {
  if (a.notificationType === NotificationType.MonthlyFinance) return -1
  if (b.notificationType === NotificationType.MonthlyFinance) return 1
  return a.notificationType.localeCompare(b.notificationType)
}

type AdminNotificationPreferencesFormProps = { adminUser: AdminUserProfileFragment }
export const AdminNotificationPreferencesForm = ({ adminUser }: AdminNotificationPreferencesFormProps) => {
  const { notificationPreferences } = adminUser
  const { mutate: updateNotificationPreferences } = useUpdateNotificationPreferences(adminUser.id)
  const formValues = React.useMemo(() => apiToForm(notificationPreferences), [notificationPreferences])
  const { control, handleSubmit } = useForm({ values: formValues })
  return (
    <form onSubmit={handleSubmit((values) => updateNotificationPreferences(formToApi(values)))}>
      <Typography variant="h2" gutterBottom>
        Set your email preferences
      </Typography>
      {notificationPreferences.sort(prefSort).map(({ notificationType }) => (
        <CheckboxController
          key={notificationType}
          control={control}
          name={notificationType}
          label={NOTIF_LABELS[notificationType]}
        />
      ))}
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" type="submit">
            Update preferences
          </Button>
        }
      />
    </form>
  )
}
