import {
  Container,
  List,
  ListItem,
  ListItemLink,
  ListItemText,
  PageBody,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { RouteChrome } from '../routes/RouteChrome'
import { RoleFragment } from './organization/gql/orgRoles_gen'

export const OrgAdminList = ({ adminRoles }: { adminRoles: RoleFragment[] }) => {
  return (
    <RouteChrome type="admin" root={{ route: '/manage', label: 'Admin' }}>
      <PageBody>
        <Container>
          <Typography variant="h2" gutterBottom>
            Select the organization you would like to manage
          </Typography>
          <List disablePadding>
            {adminRoles.map((role) => (
              <ListItem key={role.id} divider disablePadding>
                <ListItemLink to={`/manage/org/${role.resourceDisplayInfo.id}`}>
                  <ListItemText primary={role.resourceDisplayInfo.displayName} />
                </ListItemLink>
              </ListItem>
            ))}
          </List>
        </Container>
      </PageBody>
    </RouteChrome>
  )
}
