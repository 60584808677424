import { Alert, Box, Grid, HealthcheckList, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useCurrentOrgRootPath, useOrg } from '../organization/useOrg'
import { AliceCardProgramHealthcheck } from './AliceCardProgramHealthcheck'
import { useMayManageAliceCardProgram } from './aliceCardProgramUtils'
import { ConnectedPaymentMethods } from './ConnectedPaymentMethods'
import { ConnectPaymentMethodStep } from './ConnectPaymentMethodStep'
import { FundingAccountBalance } from './FundingAccountComponents'
import { PageBody } from '../../routes/PageBody'
import { isOrgFundingConnected } from './funding/fundingUtils'

export const AliceCardProgramPage = () => {
  const mayManageAliceCardProgram = useMayManageAliceCardProgram()
  const org = useOrg()
  const currentOrgRootPath = useCurrentOrgRootPath()
  if (!org) return null

  const todo = !org.onboardingState.isAlreadyLaunched
  if (!mayManageAliceCardProgram) return null
  const isFundingConnected = isOrgFundingConnected(org)

  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1">Contributions Account</Typography>
      <HealthcheckList todo={todo} sx={{ my: 3 }}>
        <AliceCardProgramHealthcheck org={org} todo={todo} />
      </HealthcheckList>

      {org.aliceCardProgram.hasFundingConnected && (
        <Box mt={3} mb={4}>
          <SectionHeading
            to={`${currentOrgRootPath}/alice-card-program/account`}
            toLabel="See account activity"
          >
            Contributions Account
          </SectionHeading>
          <Grid container columnGap={2} wrap="nowrap" flexDirection="row-reverse" sx={{ marginTop: 2 }}>
            <Alert severity="info" icon={false} sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
              Employee spend on Alice Card is automatically transferred regularly from your payroll bank
              account to your contributions account. Transfers made to the contributions account{' '}
              <em>are not Alice&apos;s fees</em>, and the funds do not belong to Alice &ndash; they belong to
              your company&apos;s commuter benefit plan.
            </Alert>
            <Grid item minWidth="25%">
              <FundingAccountBalance org={org} />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box my={3}>
        <ConnectedPaymentMethods org={org} />
      </Box>
      {isFundingConnected && <ConnectPaymentMethodStep org={org} />}
    </PageBody>
  )
}
